import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LIBRARY } from "@app/evaluation/library";
import { CompiledComparativeMeasurementSummary, Series } from "@app/evaluation/report-compiler.types";
import { ChartCompilationType, ChartContextType, ChartDateType, ChartSample, ComparativeSummaryOptions, CultevaChartTypes, MeasurementSummaryOverrides, ReportSample, SampleType } from "@core/data";
import { Snackbar } from "@core/material";
import { Library, Measurement } from "@library";
import { Subject } from "rxjs";

export interface ChartOptionsDialogData {
    measureId: string;
    samples: ReportSample[];
    overrides: MeasurementSummaryOverrides,
    series: Series[],
}

interface ChartOption {
    value: string,
    label: string
}

@Component({
    selector: 'pv-chart-options-dialog',
    templateUrl: 'chart-options.dialog.html',
    styleUrls: ['chart-options.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-fullscreen-dialog'
    }
})
export class ChartOptionsDialog implements OnInit, OnDestroy {

    chartTypeOptions: ChartOption[];

    chartTypeRegistry: Partial<CultevaChartTypes> = {
        bar: null,
        bubble: null,
        line: null,
        scatter: null,
        boxplot: null,
    };

    chartDateOptions = [
        {value: ChartDateType.DAY, label: 'Day'},
        {value: ChartDateType.WEEK, label: 'Week'},
        {value: ChartDateType.YEAR, label: 'Year'},
    ];

    chartContextOptions = [
        {value: ChartContextType.CULTIVAR, label: 'Cultivar'},
        {value: ChartContextType.EVALUATION, label: 'Evaluation'},
        {value: ChartContextType.SAMPLE, label: 'Sample'},
    ];

    chartCompilationOptions = [
        {value: ChartCompilationType.COMPARISON, label: 'Comparison'},
        {value: ChartCompilationType.SUMMARY, label: 'Summary'},
    ];

    allowsTrim: Partial<keyof CultevaChartTypes>[] = [
        'bar',
        'boxplot',
        'bubble',
        'line',
        'scatter',
    ];

    showTrim: boolean = false;

    //? Data Controls
    samplesControl: FormArray = new FormArray([]);
    chartTypeControl: FormControl = new FormControl(null, [Validators.required]);
    dateFormatControl: FormControl = new FormControl(null, [Validators.required]);
    contextControl: FormControl = new FormControl(null, [Validators.required]);
    compilationControl: FormControl = new FormControl(null, [Validators.required]);
    secondaryMeasureControl: FormControl = new FormControl(null);
    tertiaryMeasureControl: FormControl = new FormControl(null);


    //? Config Controls
    legendControl: FormControl = new FormControl(true);
    trimYControl: FormControl = new FormControl(false);
    chartLabelControl: FormControl = new FormControl(null, [Validators.maxLength(40)]);
    showBubbleSizeControl: FormControl = new FormControl(true);

    configGroup: FormGroup = new FormGroup({
        legend: this.legendControl,
        beginAtZero: this.trimYControl,
        altLabel: this.chartLabelControl,
        showBubbleSize: this.showBubbleSizeControl,
    });

    formGroup: FormGroup = new FormGroup({
        type: this.chartTypeControl,
        samples: this.samplesControl,
        dateFormat: this.dateFormatControl,
        context: this.contextControl,
        compilationType: this.compilationControl,
        secondaryMeasure: this.secondaryMeasureControl,
        tertiaryMeasure: this.tertiaryMeasureControl,
        config: this.configGroup,
    });

    measure: Measurement;
    measureType: SampleType;
    chartLabel?: string;

    measures: Measurement[];
    measureOptions1: Measurement[];
    measureOptions2: Measurement[];

    samples: ReportSample[];

    overrides: MeasurementSummaryOverrides;

    contentReady: boolean = false;

    private _destroy$ = new Subject();

    constructor(
        private _dialog: MatDialogRef<ChartOptionsDialog>,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: ChartOptionsDialogData,
        @Inject(LIBRARY) private _library: Library,
    ) {}

    ngOnInit() {
        this.chartTypeControl.valueChanges
            .subscribe((data: keyof CultevaChartTypes) => {
                this.showTrim = this.allowsTrim.includes(data);

                if (!this.showTrim) this.trimYControl.setValue(false);

                this.secondaryMeasureControl.setValidators([]);
                this.tertiaryMeasureControl.setValidators([]);

                //? Reset Scatter Controls
                if (data !== 'scatter' && data !== 'bubble') this.resetScatterControls();

                if (data === 'boxplot') {
                    this.dateFormatControl.setValue(ChartDateType.ALL);
                    if (!this.compilationControl.value) this.compilationControl.setValue(ChartCompilationType.SUMMARY);
                    return;
                }

                if (data === 'scatter' || data === 'bubble') {
                    this.dateFormatControl.setValue(ChartDateType.ALL);
                    if (data === 'scatter') this.compilationControl.setValue(ChartCompilationType.COMPARISON);

                    this.resetScatterControls();

                    this.secondaryMeasureControl.setValidators([Validators.required]);

                    if (data === 'bubble') this.tertiaryMeasureControl.setValidators([Validators.required]);

                    return;
                }

                if (this.dateFormatControl.value === ChartDateType.ALL) this.dateFormatControl.setValue(ChartDateType.WEEK);
            });

        this.secondaryMeasureControl.valueChanges.subscribe(() => { this.setMeasureOptions() });

        this.tertiaryMeasureControl.valueChanges.subscribe(() => { this.setMeasureOptions() });

        this.chartTypeOptions = Object.keys(this.chartTypeRegistry).map((option) => {
            let label = this.toTitleCase(option);
            if (option === 'boxplot') label = 'Standard Deviation Candlestick';
            return { value: option, label: this.toTitleCase(label) }
        } );

        this.overrides = this.data.overrides;

        this.measure = this._library.measures.get(this.data.measureId);

        this.measureType = this._library.checkMeasurementType(this.data.measureId);

        if (this.measureType === null) return this._snackbar.error('Error: Measurement of unkown type found');

        this.samples = this.data.samples.filter(sample => sample.data.type === this.measureType);

        this.mapChartSamples(this.samples);

        if (!this.overrides) {
            this.overrides = {
                measureId: this.measure.id,
                bucketId: this.measure.defaultBucket,
                chartId: this.measure.defaultChart,
                showChart: true,
                showData: false,
                showStats: true,
                showAverage: false,
                options: null,
                showComparativeChart: true,
            };
        }

        let measureIds: string[] = []
        this.data.series.forEach(serie => {
            serie.measures.forEach(measure => {
                if (measureIds.includes(measure.measureId) || measure.measureId === this.data.measureId) return;
                measureIds.push(measure.measureId);
            })
        });

        measureIds = measureIds.filter(meas => this._library.checkMeasurementType(meas) === this.measureType);

        this.measures = measureIds.map(id => this._library.measures.get(id));

        this.measureOptions1 = this.measures;
        this.measureOptions2 = this.measures;

        this.chartLabel = this.overrides?.options?.altLabel || this.measure?.label;

        this.reset();
    }

    reset() {
        const chartOptions: ComparativeSummaryOptions = {
            ...this.overrides?.options
        };

        let data: ComparativeSummaryOptions = {
            type: chartOptions?.type || null,
            samples: chartOptions?.samples || [],
            dateFormat: chartOptions?.dateFormat || null,
            context: chartOptions?.context || null,
            compilationType: chartOptions?.compilationType || null,
            altLabel: chartOptions?.altLabel || null,
            showBubbleSize: chartOptions?.showBubbleSize || false,
            secondaryMeasureId: chartOptions?.secondaryMeasureId || null,
            tertiaryMeasureId: chartOptions?.tertiaryMeasureId || null,
            config: chartOptions?.config || null,
        }

        this.resetStaticControls(data);

        data.samples.forEach(chartSample => {
            let sample: ReportSample = this.samples.find(sample => sample.sampleKey === chartSample.key);

            if (!sample) return;

            const samples = this.samplesControl.value;

            let sampleIndex = samples.findIndex((data) => data.key === chartSample.key);

            if (sampleIndex === -1) return;

            const evalControl = this.fetchControlByIndex(sampleIndex);

            chartSample.evaluations.forEach(evalu => {
                let evalIndex = evalControl.value.findIndex(e => e.key === evalu)
                if (evalIndex === -1) return;

                evalControl.controls[evalIndex].get('checked').setValue(true);

                this.toggleEvals(sampleIndex);
            });
        })

        this.contentReady = true;
    }

    mapChartSamples(samples: ReportSample[]) {
        samples.forEach(sample => {
            if (sample.data.type !== this.measureType) return;
            this.samplesControl.push(this.mapChartEvals(sample))
        })
    }

    mapChartEvals(sample: ReportSample): FormGroup {
        let evals = new FormArray([]);

        sample.includeEvals.forEach(evalu => {
            let sampleEval = sample.data.evals.find(e => e.key == evalu);
            if (!sampleEval) return;
            evals.push(new FormGroup({
                key: new FormControl(sampleEval.key, [Validators.required]),
                checked: new FormControl(false),
            }));
        })

        return new FormGroup({
            key: new FormControl(sample.sampleKey, [Validators.required]),
            evaluations: evals,
            checked: new FormControl(false),
        });
    }

    toggleSample(index: number) {
        setTimeout(() => {
            let sample = this.samplesControl.get(`${index}`)
            let evals = sample.get('evaluations') as FormArray;

            evals.controls.forEach(evalu => {
                evalu.get('checked').setValue(sample.value.checked)
            });
        }, 0)
    }

    toggleEvals(sampleIndex: number) {
        setTimeout(() => {
            let sample = this.samplesControl.get(`${sampleIndex}`)
            let evals = this.fetchControlByIndex(sampleIndex)
            let values = [...new Set(evals.value.map(val => val.checked || false))];

            if (values[0] === false || values.length > 1) sample.get('checked').setValue(false);
            else sample.get('checked').setValue(true)
        }, 0)
    }

    fetchControlByIndex(index: number): FormArray {
        return this.samplesControl.controls[index].get('evaluations') as FormArray;
    }

    getEvalCount(sampleIndex: number): string {
        let evals = this.fetchControlByIndex(sampleIndex)
        let values = evals.value.map(val => val.checked || false);
        let active = values.filter(val => val === true);

        return `${active.length}/${values.length}`
    }

    setMeasureOptions() {
        this.measureOptions1 = this.measures.filter(meas => this.tertiaryMeasureControl.value !== meas.id);
        this.measureOptions2 = this.measures.filter(meas => this.secondaryMeasureControl.value !== meas.id);
    }

    private toTitleCase(str: string): string {
        return str.replace(
          /\w\S*/g,
          text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    save() {
        let chartSamples: ChartSample[] = [];

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();
        this.formGroup.markAllAsTouched();

        if (!this.formGroup.valid) return this._snackbar.error('Invalid data, check your inputs and try again.');

        const form = this.formGroup.value;

        const { altLabel, showBubbleSize } = this.configGroup.value;
        const { type, dateFormat, context, compilationType, secondaryMeasure, tertiaryMeasure } = this.formGroup.value;

        form.samples.forEach((sample) => {
            const evaluations = sample.evaluations.filter((evalu) => evalu.checked === true);

            if (!evaluations.length) return;

            chartSamples.push({
                key: sample.key,
                evaluations: evaluations.map((evalu) => evalu.key),
            });
        })

        const chartOptions: ComparativeSummaryOptions = {
            type: type,
            samples: chartSamples,
            dateFormat: dateFormat,
            context: context,
            compilationType: compilationType,
            altLabel: altLabel || null,
            showBubbleSize: showBubbleSize || false,
            secondaryMeasureId: secondaryMeasure || null,
            tertiaryMeasureId: tertiaryMeasure || null,
            config: this.mapConfigSettings(),
        }

        this.overrides.options = chartOptions;

        this._dialog.close(this.overrides);
    }

    mapConfigSettings() {
        let config = {}

        Object.keys(this.configGroup.controls).forEach(key => {
            let control = this.configGroup.get(key);

            if (key === 'legend') {
                config = {
                    ...config,
                    plugins: {
                        legend: {
                            display: control.value,
                        }
                    }
                }
            }

            if (key === 'beginAtZero') {
                config = {
                    ...config,
                    scales: {
                        y: {
                            beginAtZero: !control.value,
                        }
                    }
                }
            }
        })

        return config;
    }

    resetScatterControls() {
        this.secondaryMeasureControl.reset(null);
        this.tertiaryMeasureControl.reset(null);
    }

    resetStaticControls(data: ComparativeSummaryOptions) {
        //? Data Controls
        this.chartTypeControl.reset(data.type);
        this.dateFormatControl.reset(data.dateFormat);
        this.contextControl.reset(data.context);
        this.compilationControl.reset(data.compilationType);
        this.secondaryMeasureControl.reset(data.secondaryMeasureId);
        this.tertiaryMeasureControl.reset(data.tertiaryMeasureId);

        //? Config Controls
        this.legendControl.reset(this.checkValue(data.config?.plugins?.legend?.display, true));
        this.trimYControl.reset(this.checkValue(!data.config?.scales?.y?.beginAtZero));
        this.chartLabelControl.reset(data.altLabel);
        this.showBubbleSizeControl.reset(this.checkValue(data.showBubbleSize, false));
    }

    checkValue(value: boolean, standard: boolean = false) {
        if (value === null || value === undefined) return standard;
        if (typeof value == "boolean") return value;

        return standard;
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}