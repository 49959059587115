<h1 mat-dialog-title class="dialog-title">
    <span>{{ chartLabel || 'Unkown Measurement' }}</span>
    <mat-icon [svgIcon]="'common:' + measureType + '_sample'"></mat-icon>
</h1>
<div mat-dialog-content class="dialog-content-wrapper">
    <ng-container *ngIf="contentReady; else contentLoading">
        <div class="dialog-column">
            <h4>Chart Data Option</h4>
            <form #form="ngForm"
                class="dialog-form"
                [formGroup]="formGroup">

                <mat-form-field appearance="standard">
                    <mat-label>Chart Type</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option *ngFor="let type of chartTypeOptions" [value]="type.value">{{ type.label }}</mat-option>
                    </mat-select>
                    <mat-error>Select a chart type</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Chart Context</mat-label>
                    <mat-select formControlName="context" required>
                        <mat-option *ngFor="let option of chartContextOptions" [value]="option.value">{{ option.label }}</mat-option>
                    </mat-select>
                    <mat-error>Select chart context</mat-error>
                </mat-form-field>

                <ng-container [ngSwitch]="chartTypeControl.value">
                    <!--* SCATTER -->
                    <ng-container *ngSwitchCase="'scatter'">
                        <mat-form-field appearance="standard">
                            <mat-label>Dependent Measure</mat-label>
                            <mat-select formControlName="secondaryMeasure" required>
                                <mat-option *ngFor="let option of measureOptions1" [value]="option.id">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select Dependent Measurement</mat-error>
                        </mat-form-field>
                    </ng-container>

                    <!--* BUBBLE -->
                    <ng-container *ngSwitchCase="'bubble'">
                        <mat-form-field appearance="standard">
                            <mat-label>Dependent Measure</mat-label>
                            <mat-select formControlName="secondaryMeasure" required>
                                <mat-option *ngFor="let option of measureOptions1" [value]="option.id">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select Dependent Measurement</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Radius Measure</mat-label>
                            <mat-select formControlName="tertiaryMeasure" required>
                                <mat-option *ngFor="let option of measureOptions2" [value]="option.id">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select Measurement to Determine Bubble Radius</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Chart Compilation</mat-label>
                            <mat-select formControlName="compilationType" required>
                                <mat-option *ngFor="let option of chartCompilationOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select chart data compilation type</mat-error>
                        </mat-form-field>
                    </ng-container>

                    <!--* CANDLE STICK -->
                    <ng-container *ngSwitchCase="'boxplot'"></ng-container>

                    <!--* DEFAULT CHART TYPES -->
                    <ng-container *ngSwitchDefault>
                        <mat-form-field appearance="standard">
                            <mat-label>Chart Compilation</mat-label>
                            <mat-select formControlName="compilationType" required>
                                <mat-option *ngFor="let option of chartCompilationOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select chart data compilation type</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Chart Date</mat-label>
                            <mat-select formControlName="dateFormat" required>
                                <mat-option *ngFor="let option of chartDateOptions" [value]="option.value">{{ option.label }}</mat-option>
                            </mat-select>
                            <mat-error>Select chart date intervals</mat-error>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </form>

            <!-- SAMPLE / EVAL SELECTION -->
            <mat-label class="form-label">Select Chart Data</mat-label>
            <mat-accordion [multi]="true">
                <ng-container *ngFor="let sample of samples; index as sampleIndex" [formGroup]="samplesControl.controls[sampleIndex]">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <!-- SAMPLE CHECKBOX -->
                            <mat-panel-title class="sample-title">
                                <mat-checkbox
                                    (click)="$event.stopPropagation(); toggleSample(sampleIndex)"
                                    (keydown)="$event.stopPropagation()"
                                    formControlName="checked"
                                    color="primary">
                                    {{ sample.data.label }}
                                </mat-checkbox>
                            </mat-panel-title>
                            <mat-panel-description>
                                {{ getEvalCount(sampleIndex) }}
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- EVAL CHECKBOXES -->
                        <div class="sample-content-wrapper">
                            <div class="sample-info">
                                <span *ngIf="sample?.data?.site"><strong>Site: </strong>{{ sample.data.site.name + ', ' + sample.data.site.block }}</span>

                                <ng-container *ngIf="sample.data.type === 'plant'; else harvestInfo">
                                    <span><strong>Planted Date: </strong>{{ sample.data.birthDate | amDateFormat:'ll' }}</span>
                                </ng-container>

                                <ng-template #harvestInfo>
                                    <span><strong>Harvest Date: </strong>{{ sample.data.birthDate | amDateFormat:'[Week] W' }},&nbsp;{{ sample.data.birthDate | amDateFormat:'ll' }}</span>
                                </ng-template>
                            </div>

                            <ng-container *ngFor="let evalu of sample.data.evals; index as evalIndex" [formGroup]="fetchControlByIndex(sampleIndex).controls[evalIndex]">
                                <div class="evaluation-wrapper">
                                    <mat-checkbox formControlName="checked" (click)="toggleEvals(sampleIndex)">{{ evalu.label }}</mat-checkbox>
                                    <span>{{ evalu.evalStartDate | amDateFormat:'ll' }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
        <div class="dialog-column">
            <h4>Chart Config Option</h4>
            <form #form="ngForm"
                class="dialog-form"
                [formGroup]="configGroup">

                <div class="config-option-wrapper">

                    <mat-checkbox
                        appearance="standard"
                        formControlName="legend"
                        color="primary">
                            Display Legend
                    </mat-checkbox>

                    <mat-checkbox
                        *ngIf="chartTypeControl.value === 'bubble'"
                        appearance="standard"
                        formControlName="showBubbleSize"
                        color="primary">
                            Bubble Size Legend
                    </mat-checkbox>

                    <mat-checkbox
                        *ngIf="showTrim"
                        appearance="standard"
                        formControlName="beginAtZero"
                        color="primary">
                            Zoom Graph
                    </mat-checkbox>

                    <mat-form-field appearance="standard">
                        <mat-label>Custom Chart Label</mat-label>
                        <input matInput type="text" formControlName="altLabel" autocomplete="off">
                        <mat-error>Label max 40 chars</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-template #contentLoading>
        <div class="dialog-error">
            <mat-icon class="material-symbols-outlined" color="warn">cancel</mat-icon>
            <h4>Error Loading Graph Options</h4>
        </div>
    </ng-template>
</div>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="accent">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="measureType === null" *ngIf="contentReady">Save</button>
</mat-dialog-actions>