import { Inject, Injectable } from "@angular/core";
import { CompiledComparativeMeasurementSummary, CompiledReportChart, MeasurementStats, Series } from "./report-compiler.types";
import { ChartCompilationType, ChartContextType, ChartDateType, ComparativeSummaryOptions, CultevaChartTypes } from "@core/data";
import * as moment from "moment";
import { LIBRARY } from "./library";
import { Library, SampleType } from "@library";
import { hexToRGBA } from "@core/utils";

export interface ChartData {
    date: string,
    value: number,
    seriesRef: string,
    sampleKey?: string,
    evalKey?: string
}

export type BubbleLegend = {
    value: number,
    radius: number
}

type DataMap = {
    value: number,
    label: string
}

type Dataset = {
    datasets: any[],
    labels?: any[],
    birthDate?: string[],
}

type CompiledData = {
    key: string,
    serie: Series,
    values: number[],
}

type ContextData = {
    context: string,
    dataLabel: string,
    compiledData: CompiledData[],
}

@Injectable()
export class ComparativeChartCompilerService {

    measureType: SampleType;
    dateFormat: string;
    chartDateType: ChartDateType;

    constructor(
        @Inject(LIBRARY) private _library: Library,
    ) {}

    compileComparativeChart(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart{
        this.chartDateType = options.dateFormat;

        if (options.dateFormat === ChartDateType.DAY) this.dateFormat = 'DD MMM YY'
        if (options.dateFormat === ChartDateType.WEEK) this.dateFormat = 'WW YY'
        if (options.dateFormat === ChartDateType.YEAR) this.dateFormat = 'YYYY'

        if (!measure.showChart || measure.isEmpty) return null;

        this.measureType = this._library.checkMeasurementType(measure.id);

        if (this.measureType === null) {
            console.warn('Could not determine type of measure ' + measure.id);
            return;
        }

        if (options.type === 'bar') return this.compileBarGraph(measure, options);
        if (options.type === 'line') return this.compileLineGraph(measure, options);
        if (options.type === 'boxplot') return this.compileCandlestickGraph(measure, options);
        if (options.type === 'scatter') return this.compileScatterPlotGraph(measure, options);
        if (options.type === 'bubble') return this.compileBubbleGraph(measure, options)

        return;
    }

    compileBarGraph(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart {
        let data: Dataset = {
            labels: [],
            datasets: [],
        }

        const type = 'bar';

        let chartName = this.getChartName(measure, options);

        let chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    ...options.config?.plugins?.legend
                },
            },
            tooltips: {
                mode: 'index',
                axis: 'y'
            },
            scales: {
                x: {
                    ticks: {
                        maxRotation: 90,
                        minRotation: 90,
                    },
                    stacked: false,
                    title: {
                        display: true,
                        text: chartName,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    stacked: false,
                    title: {
                        display: true,
                        text: `${measure.options.label} [${measure.options.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        color: (context) => {
                            if (context.index % 2 === 0) return '#9c9c9c';
                            return '#e8e8e8';
                        }
                    },
                    ticks:{
                        suggestedMin: 0
                    },
                    ...options.config?.scales?.y
                }
            },
            responsive: true,
        };

        if (options.compilationType === ChartCompilationType.COMPARISON) {
            let compiledData = this.compileChartData(measure, options);
            data = this.drawComparativeReport(compiledData);
        }

        if (options.compilationType === ChartCompilationType.SUMMARY) {
            data = this.compileChartData(measure, options);
        }

        return {
            options: chartOptions,
            data,
            type,
        }
    }

    // TODO add trendline package and options
    compileLineGraph(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart {
        let data: Dataset = {
            labels: [],
            datasets: [],
        };

        const type = 'line';
        let chartName = this.getChartName(measure, options);

        let chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    ...options.config?.plugins?.legend
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: chartName,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    ticks: {
                        maxRotation: 90,
                        minRotation: 90
                    },
                    grid: {
                        display: false,
                    },
                    offset: true,
                },
                y: {
                    title: {
                        display: true,
                        text: `${measure.options.label} [${measure.options.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        color: (context) => {
                            if (context.index % 2 === 0) return '#9c9c9c';
                            return '#e8e8e8';
                        }
                    },
                    ticks: {
                        suggestedMin: 0
                    },
                    offest: true,
                    ...options.config?.scales?.y
                }
            },
            responsive: true,
        };

        if (options.compilationType === ChartCompilationType.COMPARISON) {
            let compiledData = this.compileChartData(measure, options);
            data = this.drawComparativeReport(compiledData);
        }

        if (options.compilationType === ChartCompilationType.SUMMARY) {
            data = this.compileChartData(measure, options);
        }

        data.datasets = this.fillDataGaps(data.datasets);

        return {
            options: chartOptions,
            data,
            type,
        }
    }

    private compileCandlestickGraph(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart {
        let data: Dataset = {
            labels: [],
            datasets: [],
        };

        const type = 'boxplot';

        let chartName = this.getChartName(measure, options);

        const chartOptions = {
            tooltipDecimals: 2,
            plugins: {
                legend: {
                    display: true,
                    ...options.config?.plugins?.legend
                },
            },
            tooltips: {
                enabled: true,
                mode: 'nearest',
            },
            scales: {
                x: {
                    type: 'category',
                    title: {
                        display: true,
                        text: chartName,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxRotation: 90,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: `${measure.options.label} [${measure.options.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    ticks: {
                        suggestedMin: 40
                    },
                    grid: {
                        color: (context) => {
                            if (context.index % 2 === 0) return '#9c9c9c';
                            return '#e8e8e8';
                        }
                    },
                    offset: true,
                    ...options.config?.scales?.y,
                }
            },
            responsive: true,
        };


        data = this.compileCandleStickData(measure, options, data);

        return {
            options: chartOptions,
            data,
            type,
        }
    }

    private compileScatterPlotGraph(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart {
        let data: Dataset = {
            datasets: [],
        };

        const type = 'scatter';

        let secondaryMeasure = this._library.measures.get(options.secondaryMeasureId);

        const chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    ...options.config?.plugins?.legend
                },
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    title: {
                        display: true,
                        text: `${measure.options.label} [${measure.options.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: `${secondaryMeasure.label} [${secondaryMeasure.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        },
                    },
                    grid: {
                        color: (context) => {
                            if (context.index % 2 === 0) return '#9c9c9c';
                            return '#e8e8e8';
                        }
                    },
                    offset: true,
                    ...options.config?.scales?.y
                }
            },
            elements: {
                point: {
                    radius: 5,
                    hoverRadius: 7,
                }
            }
        };

        const sampleSummary = this.compileDataWithContext(measure, options, 'sampleSummary');
        const secondarySummary = this.compileDataWithContext(measure, options, 'secondarySummary');

        this.compileScatterGraphTypes(sampleSummary, data, 'x', ['y']);
        this.compileScatterGraphTypes(secondarySummary, data, 'y', ['x']);

        return {
            options: chartOptions,
            data,
            type,
        }
    }

    private compileBubbleGraph(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): CompiledReportChart {
        let data: Dataset = {
            datasets: [],
        };

        const type = 'bubble';
        let secondaryMeasure = this._library.measures.get(options.secondaryMeasureId);
        let tertiaryMeasure = this._library.measures.get(options.tertiaryMeasureId);
        let chartLabel: string = `Scatter Plot of ${measure.options.label} and ${secondaryMeasure.label} with ${tertiaryMeasure.label} radius`;

        const chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    ...options.config?.plugins?.legend
                },
                title: {
                    display: true,
                    text: chartLabel,
                    position: 'top',
                    font: {
                        weight: 'bold',
                        size: 15,
                    }
                },
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom',
                    title: {
                        display: true,
                        text: `${measure.options.label} [${measure.options.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        display: false,
                    },
                    offset: true,
                },
                y: {
                    title: {
                        display: true,
                        text: `${secondaryMeasure.label} [${secondaryMeasure.unit}]`,
                        font: {
                            weight: 'bold',
                            size: 14,
                        }
                    },
                    grid: {
                        color: (context) => {
                            if (context.index % 2 === 0) return '#9c9c9c';
                            return '#e8e8e8';
                        }
                    },
                    offset: true,
                    ...options.config?.scales?.y
                },
            },
        };

        const sampleSummary = this.compileDataWithContext(measure, options, 'sampleSummary');
        const secondarySummary = this.compileDataWithContext(measure, options, 'secondarySummary');
        const tertiarySummary = this.compileDataWithContext(measure, options, 'tertiarySummary');

        const tertiaryData: number[] = [...tertiarySummary.compiledData.reduce((acc, entry) => acc.concat(...entry.values), [])];

        const min = Math.min(...tertiaryData);
        const max = Math.max(...tertiaryData);

        let legendInfo: BubbleLegend[] = [];

        this.compileScatterGraphTypes(sampleSummary, data, 'x', ['y', 'r']);
        this.compileScatterGraphTypes(secondarySummary, data, 'y', ['x', 'r']);
        this.compileScatterGraphTypes(tertiarySummary, data, 'r', ['x', 'y'], min, max, options.compilationType, legendInfo);

        legendInfo = legendInfo.sort(function(a, b) { return a.radius - b.radius });

        let legend: BubbleLegend[] = [
            {value: null, radius: 2},
            ...this.compileBubbleLegend(legendInfo, options.compilationType)
        ];

        return {
            options: chartOptions,
            data,
            type,
            misc: {
                bubbleLegend: legend,
            }
        }
    }

    private compileChartData(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions) {
        let data: Dataset = {
            labels: [],
            datasets: [],
            birthDate: []
        };

        let seriesDates: string[] = [];
        let startDate: string;
        let endDate: string;

        measure.sampleSummary.forEach((collection) => {
            let dates: string[] = [];

            collection.forEach((item) => {
                if (!startDate || moment(startDate).toDate() > moment(item.date).toDate()) startDate = item.date;
                if (!endDate || moment(endDate).toDate() < moment(item.date).toDate()) endDate = item.date;

                let date = moment(item.date).format(this.dateFormat);
                dates.push(date);
            });

            seriesDates = [...new Set([...dates, ...seriesDates])];
        });

        seriesDates.sort((a,b) => {
            return moment(a, this.dateFormat).toDate().valueOf() - moment(b, this.dateFormat).toDate().valueOf();
        });

        seriesDates = this.sortDates(seriesDates, this.dateFormat);

        data.labels = seriesDates;

        if (options.context === ChartContextType.CULTIVAR) data = this.compileCultivarData(measure, data, seriesDates, options.type);
        if (options.context === ChartContextType.SAMPLE) data = this.compileSampleData(measure, data, seriesDates, options.type);
        if (options.context === ChartContextType.EVALUATION) data = this.compileEvaluationData(measure, data, seriesDates, options.type);

        return data;
    }

    private compileCultivarData(measure: CompiledComparativeMeasurementSummary, data: Dataset, seriesDates: string[], type: keyof CultevaChartTypes) {
        let cultivars = []

        measure.sampleSummary.forEach(summary => {
            summary.forEach(entry => {
                let cultivar = cultivars.find(e => e.scionCultivarKey === entry.scionCultivarKey);

                if (!cultivar) {
                    let serie = measure.series.find(seri => seri.ref === entry.seriesRef);

                    cultivar = {
                        scionCultivarKey: entry.scionCultivarKey,
                        values: seriesDates.map(date => null),
                        serie: serie,
                    };

                    data.birthDate.push(serie.sampleBirthDate);
                    cultivars.push(cultivar);
                }

                let index = seriesDates.findIndex(date => date === moment(entry.date).format(this.dateFormat));

                if (cultivar.values[index] > 0) return;

                let currentCultivar = summary.filter(sum => sum.scionCultivarKey === entry.scionCultivarKey);
                let sharedValues = currentCultivar.filter(data => moment(data.date).format(this.dateFormat) === moment(entry.date).format(this.dateFormat));

                let value = sharedValues.map(data => data.value).reduce((a, b) => a + b, 0) / sharedValues.length;

                cultivar.values[index] = this.getPrettyNumber(value);
            });
        });

        cultivars.forEach(cultivar => {
            if (type === 'bar') {
                data.datasets.push({
                    label: cultivar.serie.scionCultivarLabel,
                    backgroundColor: cultivar.serie.color,
                    data: cultivar.values,
                    maxBarThickness: 70,
                })
            }

            if (type === 'line') {
                data.datasets.push({
                    label: cultivar.serie.scionCultivarLabel,
                    backgroundColor: cultivar.serie.color,
                    borderColor: cultivar.serie.color,
                    data: cultivar.values,
                });
            }
        })

        return data;
    }

    private compileEvaluationData(measure: CompiledComparativeMeasurementSummary, data: Dataset, seriesDates: string[], type: keyof CultevaChartTypes) {
        let evals = [];
        measure.sampleSummary.forEach(summary => {
            summary.forEach(entry => {
                let evalu = evals.find(e => e.evalKey === entry.evalKey);

                if (!evalu) {
                    let serie = measure.series.find(seri => seri.ref === entry.seriesRef);

                    evalu = {
                        evalKey: entry.evalKey,
                        values: seriesDates.map(date => null),
                        serie: serie,
                    };

                    data.birthDate.push(serie.sampleBirthDate);
                    evals.push(evalu);
                }

                let index = seriesDates.findIndex(date => date === moment(entry.date).format(this.dateFormat));

                if (evalu.values[index] > 0) return;

                let currentEval = summary.filter(sum => sum.evalKey === entry.evalKey);
                let sharedValues = currentEval.filter(data => moment(data.date).format(this.dateFormat) === moment(entry.date).format(this.dateFormat));

                let value = sharedValues.map(data => data.value).reduce((a, b) => a + b, 0) / sharedValues.length;

                evalu.values[index] = this.getPrettyNumber(value);
            });
        });

        evals.forEach(evalu => {
            if (type === 'bar') {
                data.datasets.push({
                    label: evalu.serie.label,
                    backgroundColor: evalu.serie.color,
                    data: evalu.values,
                    maxBarThickness: 70,
                })
            }

            if (type === 'line') {
                data.datasets.push({
                    label: evalu.serie.sampleLabel,
                    backgroundColor: evalu.serie.color,
                    borderColor: evalu.serie.color,
                    data: evalu.values,
                });
            }
        });

        return data;
    }

    private compileSampleData(measure: CompiledComparativeMeasurementSummary, data: Dataset, seriesDates: string[], type: keyof CultevaChartTypes) {
        measure.sampleSummary.forEach(summary => {
            let values = seriesDates.map(date => null);
            let serie: Series = null;

            summary.forEach(entry => {
                let index = seriesDates.findIndex(date => date === moment(entry.date).format(this.dateFormat));

                if (!serie) serie = measure.series.find(seri => seri.ref === entry.seriesRef);

                if (values[index] > 0) return;

                let sharedValues = summary.filter(data => moment(data.date).format(this.dateFormat) === moment(entry.date).format(this.dateFormat));

                let value = sharedValues.map(data => data.value).reduce((a, b) => a + b, 0) / sharedValues.length;

                values[index] = this.getPrettyNumber(value);
            });

            data.birthDate.push(serie.sampleBirthDate);

            if (type === 'bar') {
                data.datasets.push({
                    label: serie.sampleLabel,
                    backgroundColor: serie.color,
                    data: values,
                    maxBarThickness: 70,
                })
            }

            if (type === 'line') {
                data.datasets.push({
                    label: serie.sampleLabel,
                    backgroundColor: serie.color,
                    borderColor: serie.color,
                    data: values,
                });
            }
        });

        return data;
    }

    private compileCandleStickData(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions, chartData: Dataset) {
        let { dataLabel, compiledData } = this.compileDataWithContext(measure, options, 'sampleSummary')

        chartData.labels = compiledData.map(data => data.serie[dataLabel]);

        if (options.context === ChartContextType.EVALUATION) {
            chartData.labels = compiledData.map(data => {return `${data.serie.ref} ${data.serie[dataLabel]}`});
        }

        compiledData.forEach(data => {
            let label = data.serie[dataLabel];

            if (options.context === ChartContextType.EVALUATION) label = `${data.serie.ref} ${data.serie[dataLabel]}`

            let dataset = {
                label: label,
                borderColor: data.serie.color,
                backgroundColor: hexToRGBA(data.serie.color, 0.5),
                borderWidth: 2,
                data: chartData.labels.map(label => null),
                maxBarThickness: 50,
                skipNull: true,
            };

            let index = chartData.labels.findIndex(dataLabel => dataLabel === label);

            let stats = this.compileStats(data.values);

            dataset.data[index] = {
                min: stats.min,
                whiskerMin: stats.min,
                max: stats.max,
                whiskerMax: stats.max,
                q1: stats.q1,
                q3: stats.q3,
                median: stats.median,
            };

            chartData.datasets.push(dataset);
        })

        return chartData;
    }

    private compileScatterGraphTypes(data: ContextData, dataset: Dataset, dataRef: string, altRefs: string[], min?: number, max?: number, compilationType?: ChartCompilationType, legend?: BubbleLegend[]): Dataset {
        const { dataLabel, compiledData, context } = data;
        const scale = 3.3333;

        compiledData.forEach((entry) => {
            let target = dataset.datasets.find(ds => ds.ref === entry.serie[context]);
            if (!target) {
                target = {
                    label: entry.serie[dataLabel],
                    backgroundColor: hexToRGBA(entry.serie.color, 0.4),
                    data: [],
                    ref: entry.serie[context]
                }

                dataset.datasets.push(target);
                target = dataset.datasets.find(ds => ds.ref === entry.serie[context]);
            }

            entry.values.forEach((value, index) => {

                //? Normalize the radius and scale between minRadius and maxRadius
                if (dataRef === 'r') {
                    let scaled = 0;
                    scaled = ((value / max) * 100) / scale;

                    let legendData: BubbleLegend = { value: value, radius: this.getPrettyNumber(scaled) };
                    legend.push(legendData);

                    value = this.getPrettyNumber(scaled);
                }

                if (target.data[index]) {
                    if (dataRef === 'r' && !value) value = 2;
                    target.data[index][dataRef] = value;
                } else {
                    target.data.push({
                        [dataRef]: value,
                        ...altRefs.reduce((acc, key) => {
                            if (key !== 'r') acc[key] = 0;
                            else acc[key] = 2;
                            return acc
                          }, {})
                    });
                }
            });
        })

        return dataset;
    }

    private compileBubbleLegend(legendData: BubbleLegend[], compilationType?: ChartCompilationType): BubbleLegend[] {
        let legend: BubbleLegend[] = [];
        const totalItems = legendData.length;

        const steps = Math.min(totalItems, 5); // Set number of legend items

        const legendIndices = Array.from({ length: steps }, (data, i) => {
            const position = i / (steps - 1);
            return Math.round(position * (totalItems - 1));
        });

        legend = legendIndices.map(index => legendData[index]);

        return legend
    }

    private compileDataWithContext(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions, summaryRef: string): ContextData {
        let context = null;
        let dataLabel = null;
        let compiledData: CompiledData[] = [];

        if (options.context === ChartContextType.CULTIVAR) {
            context = 'scionCultivarKey';
            dataLabel = 'scionCultivarLabel';
        }

        if (options.context === ChartContextType.EVALUATION) {
            context = 'evalKey';
            dataLabel = 'evalLabel';
        }

        if (options.context === ChartContextType.SAMPLE) {
            context = 'sampleKey';
            dataLabel = 'sampleLabel';
        }


        measure[`${summaryRef}`].forEach(summary => {
            summary.forEach(entry => {
                let data = compiledData.find(val => val.key === entry[context]);

                if (!data) {
                    let serie = measure.series.find(seri => seri.ref === entry.seriesRef);

                    data = {
                        key: entry[context],
                        values: [],
                        serie: serie,
                    };

                    compiledData.push(data);
                }

                data.values.push(entry.value);
            });
        });

        if (options.compilationType === ChartCompilationType.SUMMARY) {
            compiledData.forEach(data => {
                let average = this.getPrettyNumber(data.values.reduce((acc, value) => acc + value , 0) / data.values.length);
                data.values = [average];
            });
        }

        return {
            context: context,
            compiledData: compiledData,
            dataLabel: dataLabel,
        };
    }

    private drawComparativeReport(compiledData: Dataset) {
        let labels: string[] = []
        let dataMap: DataMap[][] = []
        let dateLabel: string = this.toTitleCase(this.chartDateType);
        let type: string = this.toTitleCase(this.measureType);

        if (this.measureType === SampleType.PLANT) type += 'ed' //* Planted

        compiledData.datasets.forEach((dataSet, setIndex) => {
            let firstLabel = null;
            let setLabels: string[] = []
            let setData: DataMap[] = []

            dataSet.data.forEach((value, index) => {
                let label: string = null;
                if (value == null) return;

                let indexLabel = compiledData.labels[index];

                if (!firstLabel && indexLabel != null)  {
                    firstLabel = moment(compiledData.birthDate[setIndex], 'YYYY-MM-DD').format(this.dateFormat);
                    setLabels.push(`${type}`);
                    setData.push({value: null, label: `${type}`});
                }

                if (!label) {
                    let diff = moment(indexLabel, this.dateFormat).diff(moment(firstLabel, this.dateFormat), this.getMomentDiffRange())
                    diff != 0 ? label = `${dateLabel} ${diff}` : label = `${type}`;
                }

                setLabels.push(label);
                setData.push({value: value, label: label});
            })

            dataMap.push(setData);
            labels = [...new Set([...labels, ...setLabels])];
        });

        labels.sort((a, b) => {
            if (a === `${type}`) return -1;
            if (b === `${type}`) return 1;

            // Extract the numeric value from "Month X"
            const numA = parseInt(a.split(" ")[1], 10);
            const numB = parseInt(b.split(" ")[1], 10);

            return numA - numB;
        });

        compiledData.labels = labels;

        dataMap.forEach(((entry, dataIndex) => {
            let values = labels.map(label =>  null );
            let dataLabels = entry.map(entry => entry.label);

            dataLabels.forEach((dataLabel, i) => {
                let index = labels.findIndex(label => label === dataLabel);

                if (index === -1) return;

                values[index] = entry[i].value;

            })

            compiledData.datasets[dataIndex].data = values;
        }));

        return compiledData;
    }

    private fillDataGaps(dataSets: any[]) {
        dataSets.forEach(dataSet => {
            let values = dataSet.data;

            let validIndexes: number[] = values.filter(val => val !== null).map(val => dataSet.data.indexOf(val));

            validIndexes.forEach((index, pos) => {
                if (validIndexes[pos - 1] === undefined) return;

                let prev = validIndexes[pos - 1];

                let diff = Math.abs(index - prev)

                if (diff == 1) return;

                let currentVal = values[index];
                let previousVal = values[prev];

                let step = this.getPrettyNumber(Math.abs(currentVal - previousVal) / diff);

                if (currentVal < previousVal) step = step * -1;

                let startIndex = prev + 1;

                for (let i = startIndex; i < index; i++) {
                    values[i] = values[i - 1] + step;
                }
            })

            dataSet.data = values;
        });

        return dataSets;
    }

    private getChartName(measure: CompiledComparativeMeasurementSummary, options: ComparativeSummaryOptions): string {
        if (options.type === 'boxplot') return `${this.toTitleCase(options.context)} ${measure.options.label} breakdown`;

        let date = this.toTitleCase(options.dateFormat);
        let compilation = '';
        let suffix = '';

        if (options.compilationType === ChartCompilationType.COMPARISON) {
            compilation = 'Synced ';
            suffix = ' since ';

            if (this.measureType === SampleType.PLANT) suffix += 'planting';
            else suffix += 'harvest';
        }

        let chartName = `${compilation}Avg. ${measure.options.label} per ${date}${suffix}`;

        return chartName;
    }

    private sortDates(dates: string[], dateFormat: string): string[] {
        return dates.sort((a,b) => {
            return moment(a, dateFormat).toDate().valueOf() - moment(b, dateFormat).toDate().valueOf();
        });
    }

    private getMomentDiffRange(): moment.unitOfTime.Diff {
        let label: moment.unitOfTime.Diff = 'weeks';

        if (this.chartDateType === ChartDateType.DAY) label = 'days';
        if (this.chartDateType === ChartDateType.WEEK) label = 'weeks';
        if (this.chartDateType === ChartDateType.YEAR) label = 'years';

        return label;
    }

    private toTitleCase(str: string): string {
        return str.replace(
          /\w\S*/g,
          text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    private getPrettyNumber(value: number): number {
        return +parseFloat(`${value}`).toFixed(2);
    }

    private compileStats(unsorted: number[], population = false) : MeasurementStats {

        let values: Float64Array = (new Float64Array(unsorted)).sort();

        let n = values.length;

        if(n === 0){
            throw Error("Attempting to compile stats on 0 items.");
        }

        let min = values[0];
        let max = values[n - 1];

        // min max and accum (look for errors)
        let accum: number = 0;
        values.forEach(val => {
            accum += val;
        });

        // mean (average)
        let mean: number = accum / n;

        // variance (summed distance from mean)
        let vari: number = 0;

        values.forEach(val => {
            vari += Math.pow(val - mean, 2);
        });

        if(population){
            vari = vari / n;
        }else{
            vari = vari / (n-1);
        }


        // median (middle value of sorted values array)
        let median: number, q1: number, q3: number;
        let halfN = Math.floor(n/2);

        if(n % 2)
        {
            median = values[halfN];
            q1 = values[Math.floor(halfN/2)];
            q3 = values[halfN + Math.floor(halfN/2)];
        }
        else
        {
            median = (values[halfN-1] + values[halfN]) / 2;
            q1 = values[Math.floor(halfN/2)];
            q3 = values[halfN + Math.floor(halfN/2)];
        }

        // standard deviation
        let sd: number = Math.sqrt(vari);
        let sdl: number = mean - sd;
        let sdh: number = mean + sd;

        // standard error
        let se: number = (sd/Math.sqrt(n));

        return {
            n,
            vari,
            se,
            sd,
            sdl,
            sdh,
            mean,
            median,
            min,
            max,
            values,
            q1,
            q3
        };

    }
}