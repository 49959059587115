import { NgModule } from '@angular/core';

import { LegacyChartComponent } from './components/legacy-chart.component';
import { AppMaterialModule } from '@core/material';
import { ChartFullscreenDialog } from './components/chart-fullscreen-dialog/chart-fullscreen.dialog';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './components/pv-chart/pv-chart.component';
import { ChartOptionsDialog } from './components/chart-options-dialog/chart-options.dialog';

@NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
    ],
    declarations: [
        LegacyChartComponent,
        ChartComponent,
        ChartFullscreenDialog,
        ChartOptionsDialog,
    ],
    exports: [
        LegacyChartComponent,
        ChartComponent
    ],
    providers: [
    ],
    entryComponents: [
        ChartFullscreenDialog,
        ChartOptionsDialog,
    ]
})
export class AppChartsModule { }
