import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DEFAULTS, TastingsEvaluationsIndexStateModel } from "./tastings-evaluations-index.state-model";
import { DetailRequest, Filter, FilterBuilder, FilterQueryMode, Status, translateCommonErrorStatus } from "@core/data";
import { ClearTastingsEvaluationsIndexFilter, DeleteTastingsEvaluation, InitTastingsEvaluationsIndex, LoadTastingsEvaluationsIndex, PageTastingsEvaluationsIndex, QueryTastingsEvaluationsIndex, SetTastingsEvaluationIndexSelected, SortTastingsEvaluationsIndex } from "./tastings-evaluations-index.state-actions";
import { LocalStorage } from "@core/browser";
import { TastingsEvaluationsService } from "@core/data/api/tastings-evaluation.service";
import { tap } from "rxjs/operators";
import { TastingEvaluation, TastingEvaluationIndexType } from "@core/data/types/tastings-evaluation";
import { Injectable } from "@angular/core";

@State({
    name: 'tastings_evaluations_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingsEvaluationsIndexState {
    @Selector()
    static sort(state: TastingsEvaluationsIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingsEvaluationsIndexStateModel) {
        return state.query;
    }

    @Selector()
    static data(state: TastingsEvaluationsIndexStateModel) {
        return state.data;
    }

    constructor(
        private _tastingsEvaluationsService: TastingsEvaluationsService,
        private _storage: LocalStorage,
        private _tastingsService: TastingsEvaluationsService
    ){}

    @Action(InitTastingsEvaluationsIndex)
    initTastingScoreIndex(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: InitTastingsEvaluationsIndex) {
        const state = ctx.getState();

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            type: action.type,
            subjectKey: action.subjectKey,
            status: Status.OK
        })

        this.restoreFilter(ctx);
        return ctx.dispatch(new LoadTastingsEvaluationsIndex)
    }

    @Action(LoadTastingsEvaluationsIndex)
    loadTastingsEvaluationsIndex(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: LoadTastingsEvaluationsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'tastingsEvent',
                'tastingsEvent.indexSnapshot',
                'tastingsSample',
                'tastingsSample.harvestSample',
            ],
            counts: [
                //no counts
            ]
        }

        return this._tastingsEvaluationsService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(DeleteTastingsEvaluation)
    delete(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: DeleteTastingsEvaluation) {
        return this._tastingsService.delete(action.key)
            .pipe(
                tap(res => {
                    if (!res) return;

                    let state = ctx.getState();

                    let data = state.data.filter(r => {
                        return r.key !== res.key;
                    });

                    ctx.patchState({
                        data,
                    });
                }),
            )
    }

    @Action(PageTastingsEvaluationsIndex)
    pageTastingsEvaluationsIndex(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: PageTastingsEvaluationsIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadTastingsEvaluationsIndex);
    }

    @Action(SortTastingsEvaluationsIndex)
    sortTastingsEvaluationsIndex(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: SortTastingsEvaluationsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingsEvaluationsIndex);
    }

    @Action(QueryTastingsEvaluationsIndex)
    queryTastingsEvaluationsIndex(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: QueryTastingsEvaluationsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingsEvaluationsIndex);
    }

    @Action(ClearTastingsEvaluationsIndexFilter)
    clearTastingsEvaluationsIndexFilter(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: ClearTastingsEvaluationsIndexFilter) {
        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingsEvaluationsIndex);
    }

    @Action(SetTastingsEvaluationIndexSelected)
    setTastingsEvaluationIndexSelected(ctx: StateContext<TastingsEvaluationsIndexStateModel>, action: SetTastingsEvaluationIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let tastingsEvaluation = state.data.find(e => e.key === key);
            if (tastingsEvaluation) return tastingsEvaluation;
            return state.selectedData.find(e => e.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    private getCurrentFilter(state: TastingsEvaluationsIndexStateModel): Filter {

        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (state.type === TastingEvaluationIndexType.SAMPLE) {
            if (state.subjectKey) fb.setQuery('tastingsSampleKey', state.subjectKey);
        }

        if (state.type === TastingEvaluationIndexType.EVENT) {
            if (state.subjectKey) fb.setQuery('tastingsEventKey', state.subjectKey);
        }

        if (state.query.createdAtFrom) {
            fb.setQuery('createdAtFrom', state.query.createdAtFrom, FilterQueryMode.GREATER_THAN);
        }

        if (state.query.createdAtTo) {
            fb.setQuery('createdAtTo', state.query.createdAtTo, FilterQueryMode.LESS_THAN)
        }

        // Include excludeIdent in the query
        if (state.query.excludeIdent) {
            fb.setQuery('excludeIdent', true, FilterQueryMode.NOT_EQUALS);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        };

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingsEvaluationsIndexStateModel>) {

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tastings_evaluations_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tastings_evaluations_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tastings_evaluations_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<TastingsEvaluationsIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tastings_evaluations_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tastings_evaluations_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tastings_evaluations_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });

    }
}