import { FilterPage, FilterSort, Status } from "@core/data";
import { TastingEvaluation } from "@core/data/types/tastings-evaluation";

export interface TastingsEvaluationsIndexStateModel {
    orgKey: string;
    type: string;
    subjectKey: string;

    // table/page data
    status: Status;
    data: TastingEvaluation[];
    total: number;

    // table/data options
    displayColumns: string[];
    sortOptions: { id: string, label: string}[];
    pageSizeOptions: number[];

    //filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingsEvaluationsIndexQuery;

    // selection
    selectedKeys: string[];
    selectedData: TastingEvaluation[];

}

export interface TastingsEvaluationsIndexQuery {
    search: string;
    createdAtFrom: string;
    createdAtTo: string;
    excludeIdent?: boolean;
}

export const TASTINGS_EVAL_SORT_OPTIONS: { id: string, label: string }[] = [
    { id: 'userEmail', label: 'Email' },
    { id: 'tastingsEventTitle', label: 'Title' },
    { id: 'tastingsSampleLabel', label: 'Sample Label' },
    { id: 'createdAt', label: 'Created Date' },
];

export const TASTING_EVALUATION_DISPLAY_COLUMNS = [
    'checkbox',
    'email',
    'actions',
    'event',
    'sample',
    'score',
    'tags',
    'comment',
    'timestamps',
];

export const DEFAULTS: TastingsEvaluationsIndexStateModel = {
    orgKey: null,
    subjectKey: null,
    type: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'createdAt',
        order: 'desc'
    },
    sortOptions: TASTINGS_EVAL_SORT_OPTIONS,
    pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
    selectedKeys: [],
    selectedData: [],
    displayColumns: TASTING_EVALUATION_DISPLAY_COLUMNS,
    query: {
        createdAtFrom: null,
        createdAtTo: null,
        search: null,
        excludeIdent: false,
    },
}