<pv-view #view *ngIf="state$ | async as state" [toolbarTitle]="getSubjectLabel(state)">
    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools *ngIf="selectedOrg$ | async as selectedOrg">
        <button
            mat-flat-button
            color="accent"
            (click)="$event.preventDefault(); openReimportDialog(state.orgKey, subjectKey);"
        >
            Reimport Data
        </button>

        <button mat-icon-button matTooltip="Index Actions" [matMenuTriggerFor]="scoreIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #scoreIndexMenu>
            <ng-container>
                <button mat-menu-item>
                    <mat-icon>import_export</mat-icon>
                    <span>Export Scores</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>query_stats</mat-icon>
                    <span>Visualize Scores</span>
                </button>
                <button *ngIf="isTastingsEvent(state.type)"
                        mat-menu-item
                        (click)="$event.stopPropagation(); openImportDialog(state.orgKey, subjectKey)"
                >
                    <mat-icon>publish</mat-icon>
                    <span>Import Scores</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="tastings-evaluations-selection-toolbar mat-elevation-z3" color="accent"
        *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Tasting Evaluations Selected
        </span>

        <button mat-flat-button matTooltip="Export selected Tasting Evaluations" color="primary"
            (click)="exportTastingsEvaluations(state.selectedKeys)">
            <mat-icon>file_upload</mat-icon>
        </button>

        <button mat-flat-button matTooltip="Delete selected Tasting Evaluations" color="warn"
            (click)="deleteSelected(state.selectedKeys)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->

    <!-- FOOTER -->
    <div pv-view-footer class="pv-table-footer tastings-evaluations-footer">
        <label class="mat-caption mr-12" for="sample_index_sort">Sort</label>
        <mat-select [formControl]="columnControl" id="sample_index_sort" class="tastings-evaluations-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)"
            matTooltip="Change Sort Direction">
            <mat-icon [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total" [pageSize]="state.page.size" [pageIndex]="state.page.index"
            (page)="paginate($event)" [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- FOOTER END -->

    <!-- SIDEBAR -->
    <div pv-view-sidebar *ngIf="selectedOrg$ | async as selectedOrg">

        <mat-tab-group [(selectedIndex)]="selectedTabIndex">

            <!-- FILTER TAB -->
            <mat-tab label="Filter">

                <ng-template matTabContent>
                    <form class="pv-form single-column dense p-12 tastings-evaluations-index-form"
                        [formGroup]="queryFormGroup">

                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>search</mat-icon> Search Tasting Evaluations
                            </mat-label>
                            <input type="text" matInput formControlName="search" placeholder="Enter Score Title">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Scored at From</mat-label>
                            <input matInput formControlName="createdAtFrom" [matDatepicker]="createdAtFromPicker">
                            <mat-datepicker-toggle matSuffix [for]="createdAtFromPicker">
                            </mat-datepicker-toggle>
                            <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                            <mat-datepicker #createdAtFromPicker startView="multi-year"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Scored at To</mat-label>
                            <input matInput formControlName="createdAtTo" [matDatepicker]="createdAtToPicker">
                            <mat-datepicker-toggle matSuffix [for]="createdAtToPicker">
                            </mat-datepicker-toggle>
                            <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                            <mat-datepicker #createdAtToPicker startView="multi-year"></mat-datepicker>
                        </mat-form-field>

                        <mat-label>
                            <mat-checkbox appearance="standard" formControlName="excludeIdent" name="autorenew">
                                <span matSuffix>Exclude Anonymous User Scores</span>
                            </mat-checkbox>
                        </mat-label>

                        <div class="form-actions">
                            <button mat-button type="button" color="warn" (click)="resetFilter()">
                                Clear Filters
                            </button>
                        </div>

                    </form>

                </ng-template>

            </mat-tab>
        </mat-tab-group>

    </div>
    <!-- SIDEBAR END -->

    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()"
                matTooltip="Add Tasting Evaluation">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>
    <!-- FAB END -->

    <!-- BODY STATUS: OK -->
    <div pv-view-body class="tastings-evaluations-index-body" [ngSwitch]="state.status">

        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Tasting Evaluations..."></pv-view-loader>

        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'" heading="No results"
            message="The current filters does not match any Scores. Update the filters or add a Score." icon="error">
            <button mat-raised-button color="accent" pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction (click)="add()">
                Add
            </button>
        </pv-view-message>

        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'" heading="Invalid Filter Parameters"
            message="Unable to load data with given filter and sort parameters." icon="error">
            <button mat-raised-button color="accent" pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction (click)="reload()">
                Reload
            </button>
        </pv-view-message>

        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
            [message]="state.status | descStatusMessage:'Score'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction (click)="reload()">Reload</button>
        </pv-view-message>

        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <ng-container [ngSwitch]="selectedTabIndex" *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <!-- TABLE -->
            <div class="pv-responsive-table">
                <table cdk-table *ngSwitchDefault class="pv-tastings-evaluations-table" [dataSource]="state.data"
                    [trackBy]="trackByKey" matSort [matSortActive]="state.sort.column"
                    [matSortDirection]="state.sort.order" [matSortDisableClear]="true" (matSortChange)="sort($event)">

                    <ng-container cdkColumnDef="checkbox">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                                [checked]="selection.hasValue() && isAllSelected(state.data)"
                                [indeterminate]="selection.hasValue() && !isAllSelected(state.data)" class="no-print">
                            </mat-checkbox>
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row.key) : null"
                                    [checked]="selection.isSelected(row.key)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="email">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="userEmail">User Email</th>
                        <td cdk-cell *matCellDef="let row">{{ row.scores?.user | descUserEmail }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                        <th cdk-header-cell *cdkHeaderCellDef class="no-print"></th>
                        <td cdk-cell *matCellDef="let row" class="no-print">
                            <button mat-icon-button (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="tastingsEvaluationMenu"
                                [matMenuTriggerData]="{tastingsEvaluation: row}" matTooltip="More Actions">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="event">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="tastingsEventTitle">Tastings Event</th>
                        <td cdk-cell *matCellDef="let row">
                            <span *ngIf="row.tastingsEvent" [pvTastingEventLine]="row.tastingsEvent"
                                (click)="$event.stopPropagation();"
                                matTooltip="{{row.tastingsEvent.title}} - {{row.tastingsEvent.code}}"
                                [routerLink]="['/org', row.ownerOrgKey, 'tastings-events', {eventCode: row.tastingsEvent.code}]">
                            </span>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="sample">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="tastingsSampleLabel">Tastings Sample</th>
                        <td cdk-cell *matCellDef="let row">
                            <span *ngIf="row.tastingsSample" [pvTastingSampleLine]="row.tastingsSample"
                                (click)="$event.stopPropagation();"
                                matTooltip="{{row.tastingsSample.label}} - {{row.tastingsSample.code}}"
                                [routerLink]="['/org', row.ownerOrgKey, 'tastings-samples', {sampleCode: row.tastingsSample.code}]">
                            </span>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="score">
                        <th cdk-header-cell *cdkHeaderCellDef>Average Score</th>
                        <td cdk-cell *matCellDef="let row">{{ row?.scores?.characteristics | descAvgScore }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="tags">
                        <th cdk-header-cell *cdkHeaderCellDef>Tags</th>
                        <!-- TODO change call to fit new pipe (read index from snapshot)-->
                        <td cdk-cell *matCellDef="let row">{{ row?.trueTagCount }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="comment">
                        <th cdk-header-cell *cdkHeaderCellDef>Comment</th>
                        <td cdk-cell *matCellDef="let row">{{ row?.commentCount }}</td>
                    </ng-container>

                    <ng-container cdkColumnDef="timestamps">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="createdAt">Added</th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="caption-line">{{ row.createdAt | date:'medium' }}</span>
                        </td>
                    </ng-container>

                    <!-- Row Defs -->
                    <tr cdk-header-row *cdkHeaderRowDef="state.displayColumns">
                    </tr>
                    <tr cdk-row *cdkRowDef="let row; columns: state.displayColumns;"
                        [class.selected]="selection.isSelected(row.key)">
                    </tr>

                </table>
            </div>

            <!-- Sample Menu -->
            <mat-menu #tastingsEvaluationMenu="matMenu">
                <ng-template matMenuContent let-tastingsEvaluation="tastingsEvaluation">
                    <button mat-menu-item (click)="delete(tastingsEvaluation)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete Tasting Evaluation</span>
                    </button>
                </ng-template>
            </mat-menu>
        </ng-container>
    </div>
    <!-- BODY STATUS: OK - END -->
</pv-view>