import { Chart, ChartConfiguration, ChartOptions, ChartTypeRegistry, RadialLinearScale, registerables } from 'chart.js';
import { Component, ElementRef, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Dialog } from '@core/material';
import { ChartFullscreenDialog, ChartFullscreenDialogData } from './chart-fullscreen-dialog/chart-fullscreen.dialog';
import "@sgratzl/chartjs-chart-boxplot/build/index.umd.js";
import 'chartjs-adapter-moment';

Chart.register(...registerables);

@Component({
    selector: 'pv-legacy-chart',
    template: `
        <div #container class="chart-container">
            <canvas #canvas [ngClass]="{'chart-canvas': expandable, 'fullscreen-canvas': !expandable}"></canvas>
            <button class="chart-button no-print"
                [class.hidden-button]="!showButton"
                (click)="openFullScreenDialog()"
                mat-icon-button
                matTooltip="Full Screen">
                <mat-icon>fullscreen</mat-icon>
            </button>
        </div>`,
    styleUrls: ['legacy-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-legacy-chart',
    }
})
export class LegacyChartComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    set type(type: keyof ChartTypeRegistry) {
        if (type && this._type$.getValue() !== type) {
            this._type$.next(type);
        }
    }

    @Input()
    set data(data: any) {
        if (data && this._data$.getValue() !== data) {
            this._data$.next(data);
        }
    }

    @Input()
    set options(options: ChartOptions) {
        if (options && this._options$.getValue() !== options) {
            this._options$.next(options);
        }
    }

    @Input()
    set devicePixelRatio(ratio: number) {
        if (ratio && this._devicePixelRatio$.getValue() !== ratio) {
            this._devicePixelRatio$.next(ratio);
        }
    }

    @Input()
    expandable: boolean = true;

    @Input()
    chartLabel: string;

    @ViewChild('canvas', { static: true })
    canvasRef: ElementRef;

    @ViewChild('container', { static: true })
    containerRef: ElementRef;

    showButton: boolean;
    allowsFullScreen: string[] = ['radar'];

    private _type$ = new BehaviorSubject<keyof ChartTypeRegistry>(null);
    private _options$ = new BehaviorSubject<ChartOptions>(null);
    private _data$ = new BehaviorSubject<any>(null);
    private _devicePixelRatio$ = new BehaviorSubject<number>(null);
    private _destroy$ = new Subject();

    private _canvas: HTMLCanvasElement;
    private _chart: Chart;

    constructor(
        private _host: ElementRef,
        private _dialog: Dialog,
    ) { }

    ngOnInit() {
        this.showButton = this.allowsFullScreen.includes(this._type$.value);
        if (!this.expandable) this.showButton = false;
    }

    ngAfterViewInit() {

        this._canvas = this.canvasRef.nativeElement;

        combineLatest(
                this._type$,
                this._options$,
                this._data$,
                this._devicePixelRatio$
            )
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(100),
            )
            .subscribe(changes => {

                let [type, options, data, devicePixelRatio] = changes;

                try {

                    if (!type || !options || !data) {
                        if (this._chart) {
                            this._chart.destroy();
                            this._chart = null;
                        }
                    }

                    if (this.allowsFullScreen.includes(type) && this.showButton == false) options = this.setFullScreenDefaults(type, options);

                    const config = this._chart ? this._chart.config as ChartConfiguration : null;

                    if (!this._chart || config.type !== type) {
                        if (this._chart) this._chart.destroy();

                        this._chart = new Chart(this._canvas, {
                            type,
                            options: { ...options, devicePixelRatio },
                            data
                        });
                    } else {
                        this._chart.options = { ...options, devicePixelRatio };
                        this._chart.data = data;
                        this._chart.update();
                    }

                } catch (error) {
                    console.error("ChartComponent: Error compiling chart", error);

                    if (this._chart) {
                        this._chart.destroy();
                        this._chart = null;
                    }
                }


            });

    }

    ngOnDestroy() {

        this._destroy$.next();
        this._destroy$.complete();

        if (this._chart) {
            this._chart.destroy();
            this._chart = null;
        }

        this._type$.complete();
        this._options$.complete();
        this._data$.complete();

    }

    openFullScreenDialog() {
        let data: ChartFullscreenDialogData = {
            type: this._type$.value,
            data: this._data$.value,
            options: this._options$.value,
            ratio: this._devicePixelRatio$.value,
            title: this.chartLabel || "Chart Preview",
        }

        this._dialog.openFullscreen(ChartFullscreenDialog, {data})
    }

    setFullScreenDefaults(type: keyof ChartTypeRegistry, options) {
        if (options.plugins?.legend) {
            options.plugins.legend = {
                ...options.plugins.legend,
                labels: {
                    font: {
                        size: 16
                    }
                }
            }
        }

        if (type === 'radar') {
            options.scales.r.pointLabels.font = {
                size: 16,
            }
            options.aspectRatio = 2.3;
        }

        return options;
    }

}
