<!-- TODO pass in report remark data -->
 <ng-container>
    <div class="chart-header report-subsection-header editable" (click)="editChartOptions()">
        <div>
            {{ diaplayLabel }}
        </div>
        <mat-icon class="no-print">settings</mat-icon>
    </div>
</ng-container>
<div class="chart-wrapper" [hidden]="!chartReady">
    <div #pvChartContainer class="chart-container">
        <canvas #pvCanvas class="chart-canvas"></canvas>

        <!--* BUBBLE LEGEND -->
        <div class="bubble-legend" *ngIf="getChartType() === 'bubble' && overrides?.options?.showBubbleSize">
            <ng-container *ngIf="meta && meta.bubbleLegend; else noLegend">
                <div class="legend-item" *ngFor='let legend of meta.bubbleLegend'>
                    <div class="size-container">
                        <div class="legend-size" [ngStyle]="{'height': legend.radius * 0.111+'rem', 'width': legend.radius * 0.111+'rem'}"></div>
                    </div>
                    <span>{{ legend.value ? legend.value : 'Missing Value' }}</span>
                </div>
            </ng-container>
            <ng-template #noLegend>
                Legend Unavailable
            </ng-template>
        </div>
    </div>
</div>
<div [hidden]="chartReady">
    <div class="chart-loading no-print">
        <mat-icon class="material-symbols-outlined loading-icon">insert_chart</mat-icon>
        <span>No chart data to display, adjust options and try again.</span>
    </div>
</div>