import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject, SystemJsNgModuleLoader } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { ReportService, Status, ReportTemplate, Report, translateCommonErrorStatus, ReportCreateRequest, ReportRemark } from '@core/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Dialog, Snackbar } from '@core/material';
import { REPORT_OPTIONS_DEFAULT } from './report-defaults';


export interface ReportSetupFormDialogData {
    ownerOrgKey: string;
    sampleKeys?: string[];
    report?: Partial<Report>;
    reportKey?: string;
}


@Component({
    selector: 'pv-report-setup-form-dialog',
    templateUrl: 'report-setup-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styleUrls: ['./report-setup-form.dialog.scss']
})
export class ReportSetupFormDialog implements OnInit, OnDestroy {

    titleControl = new FormControl(null, [Validators.required, Validators.maxLength(100)]);
    descriptionControl = new FormControl(null, [Validators.maxLength(255)]);
    templateControl = new FormControl(null, [Validators.required]);
    replaceRemarksControl = new FormControl(false);
    reportKey: string;

    formGroup = new FormGroup({
        title: this.titleControl,
        description: this.descriptionControl,
        _template: this.templateControl,
    });

    orgKey: string;
    sampleKeys: string[];
    status: Status;
    templateOptions: ReportTemplate[];

    blankTemplateOption: Partial<ReportTemplate> = {
        name: "Blank Report (default)",
        reportOptions: REPORT_OPTIONS_DEFAULT,
        reportRemarks: []
    };

    public templateFilterCtrl: FormControl = new FormControl('');
    public filteredTemplates: ReplaySubject<ReportTemplate[]> = new ReplaySubject<ReportTemplate[]>(1);

    private _destroy$ = new Subject();

    constructor(
        private _reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) public _data: ReportSetupFormDialogData,
        private _dialog: MatDialogRef<ReportSetupFormDialog>,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) {}

    ngOnInit() {

        this._dialog.updateSize();

        this.orgKey = this._data.ownerOrgKey;
        this.sampleKeys = this._data.sampleKeys;
        this.reportKey = this._data.reportKey;

        this.reset();

        this.templateFilterCtrl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(value => {
                this.filterTemplateOptions()
            });
    }

    reset() {

        this.status = Status.LOADING;
        this.formGroup.disable();

        this._reportService.listTemplates(this.orgKey)
            .pipe(
                takeUntil(this._destroy$)
            )
            .subscribe((templates) => {
                    this.status = Status.OK;
                    this.templateOptions = templates;
                    this.formGroup.enable();
                    this.formGroup.reset({
                        title: null,
                        description: null,
                        _template: this.blankTemplateOption
                    });

                    // set initial selection
                    this.templateControl.setValue(this.blankTemplateOption);

                    // load the initial blank list
                    const defaultOption: ReportTemplate = {
                        id: 0,
                        name: "Blank Report (default)",
                        ownerOrgKey: this.orgKey,
                        reportOptions: REPORT_OPTIONS_DEFAULT,
                        reportRemarks: []
                    }

                    this.templateOptions.unshift(defaultOption)
                    this.filteredTemplates.next(this.templateOptions.slice());

                    if (!this._data.report) return;

                    this.formGroup.patchValue({
                        title: this._data.report.title,
                        description: this._data.report.description,
                    })
                },
                error => {
                    this._dialog.close();
                    this._dialogs.error(error);
                }
            );

    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.disabled) return;

        if(this.formGroup.valid){

            if (this._data.report) return this.applyReportTemplate();

            this.formGroup.disable();
            this.status = Status.LOADING;

            const form = this.formGroup.value;

            const data: ReportCreateRequest = {
                title: form.title,
                description: form.description,
                ownerOrgKey: this.orgKey,
                sampleKeys: this.sampleKeys || null
            };

            if(form._template){

                if(form._template.reportOptions)
                    data.options = form._template.reportOptions;

                if(Array.isArray(form._template.reportRemarks))
                    data.remarks = form._template.reportRemarks;
            }

            let dataCopy = data;
            delete dataCopy.options.showStorageRegime;

            let hasCustomTableRemarks = dataCopy.remarks ? dataCopy.remarks.map(remark => remark.sectionId).filter(id => id.includes('tableReports.customTable')) : false;

            this._reportService.create(dataCopy)
                .pipe(takeUntil(this._destroy$))
                .subscribe(
                    report => {
                        this.reportKey = report.key
                        if (hasCustomTableRemarks) return this.updateRemarkSectionId(report);

                        this._snackbar.info(`Created report '${report.title}'`);
                        this._dialog.close(report);
                    },
                    error => {
                        this.status = translateCommonErrorStatus(error);
                        this.formGroup.enable();
                    }
                );

        }else{
            this._snackbar.formInvalid();
        }
    }

    applyReportTemplate() {
        const form = this.formGroup.value;
        const report: Partial<Report> = JSON.parse(JSON.stringify(this._data.report));

        const measureSummary = {
            ...REPORT_OPTIONS_DEFAULT.measureSummaryOptions,
            ...form._template.reportOptions?.measureSummaryOptions
        }

        const selectedTemplate: ReportTemplate = {
            ...form._template,
            reportOptions: {
                ...REPORT_OPTIONS_DEFAULT,
                ...form._template.reportOptions,
                measureSummaryOptions: {
                    ...measureSummary
                }
            },
            reportRemarks: form._template.reportRemarks ? form._template.reportRemarks : []
        }

        if (!selectedTemplate) return this._snackbar.error('Unable to apply template');

        let template: Partial<ReportTemplate> = {
            ...selectedTemplate,
            reportOptions: {
                ...selectedTemplate.reportOptions,
                cultivarInfoOptions: {
                    ...selectedTemplate.reportOptions.cultivarInfoOptions,
                    includeImages: report.options.cultivarInfoOptions?.includeImages || REPORT_OPTIONS_DEFAULT.cultivarInfoOptions.includeImages,
                    infoSheetImageOptions: report.options.cultivarInfoOptions?.infoSheetImageOptions || REPORT_OPTIONS_DEFAULT.cultivarInfoOptions.infoSheetImageOptions,
                    primaryCultivar: report.options.cultivarInfoOptions?.primaryCultivar ? report.options.cultivarInfoOptions.primaryCultivar.key as any : null,
                    controlCultivar: report.options.cultivarInfoOptions?.controlCultivar ? report.options.cultivarInfoOptions.controlCultivar.key as any : null,
                },
                imageSectionOptions: report.options.imageSectionOptions || REPORT_OPTIONS_DEFAULT.imageSectionOptions,
            },
            reportRemarks: [
                ...report.remarks.filter(remark => !remark.sectionId.includes('tableReports.customTable')),
            ]
        }

        selectedTemplate.reportRemarks.forEach(remark => {
            if (remark.sectionId.includes('tableReports.customTable')) return template.reportRemarks.push(remark);
            let reportRemark = template.reportRemarks.find(re => re.sectionId === remark.sectionId);
            if (!reportRemark) return template.reportRemarks.push(remark);
            if (this.replaceRemarksControl.value) reportRemark.text = remark.text;
        });

        let dataCopy: Partial<Report> = {
            ...report,
            title: form.title,
            description: form.description,
            options: template.reportOptions,
            tables: template.reportOptions.tables || [],
            remarks: template.reportRemarks || [],
        }

        delete dataCopy.options.showStorageRegime;
        delete dataCopy.options['legendOptions'];

        let hasCustomTableRemarks = dataCopy.remarks ? dataCopy.remarks.map(remark => remark.sectionId).filter(id => id.includes('tableReports.customTable')) : false;

        if (hasCustomTableRemarks)  return this.updateRemarkSectionId(dataCopy, false, true)

        this._dialog.close({report: dataCopy, replaceRemarks: this.replaceRemarksControl.value});
    }

    updateRemarkSectionId(report: Partial<Report>, notify: boolean = true, applyTemplate: boolean = false) {
        if (!applyTemplate) report.remarks = this.updateReportTableRemark(report);

        this._reportService.update(this.reportKey, report)
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                result => {
                    if(notify) this._snackbar.info(`Created report '${result.title}'`);
                    if (!applyTemplate) return this._dialog.close(result);

                    result.remarks = this.updateReportTableRemark(result);
                    return this._dialog.close({ report: result, replaceRemarks: this.replaceRemarksControl.value });
                },
                error => {
                    this.status = translateCommonErrorStatus(error);
                    this.formGroup.enable();
                }
            );
    }

    updateReportTableRemark(report: Partial<Report>) {
        const form = this.formGroup.value;

        let template: Partial<ReportTemplate> = {
            ...form._template,
            reportOptions: {
                ...REPORT_OPTIONS_DEFAULT,
                ...form._template.reportOptions,
            },
            reportRemarks: form._template.reportRemarks ? form._template.reportRemarks : []
        }

        if (report.remarks) {
            report.remarks.forEach(remark => {
                if (remark.sectionId.includes('tableReports.customTable.')) {
                    if (Array.isArray(report.tables)) {
                        let idParts = remark.sectionId.split('.');
                        let table = template.reportOptions.tables.find(table => table.id.toString() === idParts[idParts.length - 1]);
                        if (!table) return;
                        let reportTable = report.tables.find(reportTable => table.index === reportTable.index && table.title === reportTable.title);
                        if (!reportTable) return;
                        remark.sectionId = `tableReports.customTable.${reportTable.id}`
                    }
                }
            });
        }

        return report.remarks
    }

    protected filterTemplateOptions() {
        if (!this.templateOptions) {
          return;
        }

        // get the search keyword
        let search = this.templateFilterCtrl.value;
        if (!search) {
          this.filteredTemplates.next(this.templateOptions.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the templates
        this.filteredTemplates.next(
          this.templateOptions.filter(option => option.name.toLowerCase().indexOf(search) > -1)
        );
      }

    cancel(){
        this._dialog.close(false);
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

}