import { Category, CategoryDictionary } from './categories';
import { Characteristic, CharacteristicDictionary } from './characteristics';
import { Crop, CropDictionary, CropSubject, SubjectDictionary } from './crops';
import { Measurement, MeasurementDictionary } from './measures';
import { BucketOptions, BucketDictionary } from './buckets';
import { ChartOptions, ChartDictionary } from './charts';
import { Property, PropertyDictionary } from './properties';
import { Protocol, ProtocolDictionary } from './protocols';
import { Schedule, ScheduleDictionary } from './schedules';
import { Index, IndexDictionary } from './indexes';
import { WeatherSensorDictionary, WeatherSensor, WeatherReport, WeatherReportDictionary } from './weather';
import { CalculationSubjectGroup, CharacteristicCategoryGroup, MeasurementSubjectGroup } from './groups';
import { FlagDictionary } from './flags';
import { ModuleDictionary } from './modules';
import { Calculation, CalculationDictionary } from './calculations';
import { TastingsIndex } from './tastings-indexes/_types';
import { TastingsIndexDictionary } from './tastings-indexes';
import { LocaleTastingEvaluation, LocaleTastingEvaluationDictionary, TastingEvaluation, TastingEvaluationDictionary } from './tastings-evaluations';

export interface LibraryModel {
    categories: Category[];
    chars: Characteristic[];
    indexes: Index[];
    crops: Crop[];
    subjects: CropSubject[];
    measures: Measurement[];
    calculations: Calculation[];
    buckets: BucketOptions[];
    charts: ChartOptions[];
    properties: Property[];
    protocols: Protocol[];
    tastingsIndexes: TastingsIndex[];
    tastingEvaluations: TastingEvaluation[];
    localeTastingEvaluations: LocaleTastingEvaluation[];
    schedules: Schedule[];
    weatherSensors: WeatherSensor[];
    weatherReports: WeatherReport[];
}

export enum SampleType {
    PLANT = 'plant',
    HARVEST = 'harvest',
}

export class Library {

    public categories = new CategoryDictionary();
    public chars = new CharacteristicDictionary();
    public indexes = new IndexDictionary();
    public crops = new CropDictionary();
    public subjects = new SubjectDictionary();
    public measures = new MeasurementDictionary();
    public calculations = new CalculationDictionary();
    public buckets = new BucketDictionary();
    public charts = new ChartDictionary();
    public properties = new PropertyDictionary();
    public protocols = new ProtocolDictionary();
    public tastingsIndexes = new TastingsIndexDictionary();
    public tastingEvaluations = new TastingEvaluationDictionary();
    public localeTastingEvaluations = new LocaleTastingEvaluationDictionary();
    public schedules = new ScheduleDictionary();
    public weatherSensors = new WeatherSensorDictionary();
    public weatherReports = new WeatherReportDictionary();
    public flags = new FlagDictionary();
    public modules = new ModuleDictionary();

    compatibleBuckets(measureId: string){
        return this.buckets.all().filter(bucket => {
            return bucket.measureId === measureId;
        });
    }

    protocolsByCropSample(cropId: string, sampleType: string){

        let cropProtocolIds = this.crops.get(cropId).protocols;

        return this.protocols.all().filter( protocol => {
            return (cropProtocolIds.indexOf(protocol.id) !== -1 && (protocol.sampleType === sampleType));
        });
    }

    filterExcludedCrops(): Crop[]{
        return this.crops.all().filter(( crop ) => !(this.getExcludedCropList().indexOf(crop.id) >= 0))
    }

    getCropById(cropId: string): Crop[] {
        return this.crops.all().filter(( crop ) => crop.id === cropId);
    }

    getExcludedCropList(): string[]{
        return ["hybrid_citrus"]
    }

    flagById(flagId: string){

        return this.flags.all().filter(flags => {
            return flags.id === flagId;
        });
    }

    schedulesByCrop(cropId: string){
        let cropScheduleIds = this.crops.get(cropId).schedules;

        return cropScheduleIds.map(id => this.schedules.get(id));
    }

    characteristicsGroupedByCategory(): CharacteristicCategoryGroup[]{
        const groups: CharacteristicCategoryGroup[] = [];

        this.chars.all().forEach(char => {

            let group = groups.find(group => group.id === char.categoryId);

            if(!group){
                group = {
                    id: char.categoryId,
                    chars: [],
                    category: this.categories.get(char.categoryId)
                };
                groups.push(group);
            }

            group.chars.push(char);

        });

        return groups;
    }

    measurementsGroupedBySubject(): MeasurementSubjectGroup[]{
        const groups: MeasurementSubjectGroup[] = [];

        this.measures.all().forEach(measure => {

            let group = groups.find(group => group.id === measure.subjectId);

            if(!group){
                group = {
                    id: measure.subjectId,
                    measures: [],
                    subject: this.subjects.get(measure.subjectId)
                };
                groups.push(group);
            }

            group.measures.push(measure);

        });

        return groups;
    }

    checkMeasurementType(measureId: string): SampleType {
        let protocols = this.protocols.all();

        let plantMeasures: string[] = [];
        let harvestMeasures: string[] = [];

        protocols.forEach(protocol => {
            if (protocol.sampleType === SampleType.PLANT) plantMeasures = [...new Set([...plantMeasures, ...protocol.measures])];
            if (protocol.sampleType === SampleType.HARVEST) harvestMeasures = [...new Set([...harvestMeasures, ...protocol.measures])];
        })

        if (plantMeasures.find(id => id === measureId)) return SampleType.PLANT;
        if (harvestMeasures.find(id => id === measureId)) return SampleType.HARVEST;

        return null;
    }

    calculationsGroupedBySubject(): CalculationSubjectGroup[]{
        const groups: CalculationSubjectGroup[] = [];

        this.calculations.all().forEach(calc => {

            let group = groups.find(group => group.id === calc.subjectId);

            if(!group){
                group = {
                    id: calc.subjectId,
                    calculations: [],
                    subject: this.subjects.get(calc.subjectId)
                };
                groups.push(group);
            }

            group.calculations.push(calc);

        });

        return groups;
    }

    serialize(): LibraryModel{
        return {
            categories: this.categories.serialize(),
            chars: this.chars.serialize(),
            indexes: this.indexes.serialize(),
            crops: this.crops.serialize(),
            measures: this.measures.serialize(),
            calculations: this.calculations.serialize(),
            buckets: this.buckets.serialize(),
            charts: this.charts.serialize(),
            properties: this.properties.serialize(),
            protocols: this.protocols.serialize(),
            tastingsIndexes: this.tastingsIndexes.serialize(),
            tastingEvaluations: this.tastingEvaluations.serialize(),
            localeTastingEvaluations: this.localeTastingEvaluations.serialize(),
            schedules: this.schedules.serialize(),
            subjects: this.subjects.serialize(),
            weatherSensors: this.weatherSensors.serialize(),
            weatherReports: this.weatherReports.serialize()
        };
    }

}
