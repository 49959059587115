<ng-container>
    <h3 mat-dialog-title>{{ data.title }}</h3>

    <div mat-dialog-content>
        <div class="chart-wrapper">
            <pv-legacy-chart
                class="fullscreen-chart"
                [type]="data.type"
                [data]="data.data"
                [options]="data.options"
                [devicePixelRatio]="data.ratio"
                [expandable]="false">
            </pv-legacy-chart>
        </div>
    </div>

    <mat-dialog-actions align="end">
        <button mat-flat-button mat-dialog-close color="primary">Close</button>
    </mat-dialog-actions>
</ng-container>