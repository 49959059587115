import { Sample } from './sample';



export interface Evaluation {
    key: string;
    sampleKey?: string;
    label: string;
    evalStartDate: any;
    evalEndDate: any;
    size: number;
    protocolId: string;

    sampleKeys?: string[];
    sampleCodes?: string[];
    massAction?: boolean;
    sample?: Sample;
    measures?: SampleMeasurement[];
    calculation?: SampleCalculation[];
    chars?: SampleCharacteristic[];
    notes?: SampleNote[];
    images?: SampleImage[];

    storageRegime: SampleStorage[];
    scheduleId: string;

    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface SampleStorage {
    temp: number;
    duration: string;
    treatment?: string[];
    ca?: boolean;
    caOptions?: {
        co2?: number,
        o2?: number,
        eth?: number,
        rh?: number,
    };
}

export interface SampleCharacteristic {
    charId: string;
    value: string;
}

export interface SampleMeasurement {
    measureId: string;
    value: number;
    index: number;
    calculatedValue?: number;
    allValues?: number[];
    total?: number;
    sum?: number;
    createdAt?: string;
    updatedAt?: string;
}

export interface SampleCalculation {
    calcId: string;
    value: number;
    index: number;
}

export interface SampleNote {
    key: string;
    type: string;
    message: string;
    takenAt: string;
    deletedAt: string;
}

export interface SampleImage {
    key: string;
    name: string;
    evalKey: string;
    width: number;
    height: number;
    fileRef: string;
    note: string;
    takenAt: string;
    lat: number;
    lng: number;
    alt: number;
    deletedAt: string;
}

export enum EvaluationStatus {
    /**
     * After start date and before end date
     */
    ONGOING = 'ONGOING',

    /**
     * Before start date
     */
    PENDING = 'PENDING',

    /**
     * After end date
     */
    COMPLETE = 'COMPLETE',

    /**
     * Invalid/missing dates
     */
    INVALID = 'INVALID',
}
