import { Inject, Injectable } from "@angular/core";
import { LIBRARY } from '@app/evaluation/library';
import { WeatherReportData, WeatherReportInterval } from "@core/data";
import { uniqueStrings } from '@core/utils';
import { Library } from '@library';
import { ChartData, ChartOptions, ChartTypeRegistry, TimeUnit } from 'chart.js';
import * as moment from 'moment';



export interface WeatherChart {
    type: string;
    options: any;
    data: ChartData;
}

@Injectable()
export class WeatherChartCompilerService {

    constructor(@Inject(LIBRARY) private _library: Library) { }

    compileReportChart(reportData: WeatherReportData, dense = false): WeatherChart {
        let report = this._library.weatherReports.get(reportData.reportId); //review : import data

        if (!report) throw Error(`No report with id '${reportData.reportId}'`);

        let datasets = [];
        let unitLabels = [];
        let units = [];
        let timeUnit: TimeUnit;

        switch (reportData.interval) {
            case WeatherReportInterval.HOURLY:
                timeUnit = 'hour';
                break;
            case WeatherReportInterval.DAILY:
                timeUnit = 'day';
                break;
            default:
                timeUnit = 'month';
        }

        report.attributes.forEach(attribute => {
            unitLabels.push(attribute.unitLabel);
            units.push(attribute.unit);


            let data = [];

            reportData.data.forEach(row => {

                if (row[attribute.id] !== undefined && row[attribute.id] !== null) {
                    data.push({
                        x: this.handleDateOffset(row.ts, reportData.station.type, reportData.interval),
                        y: row[attribute.id]
                    });
                }else{
                    data.push({
                        x: this.handleDateOffset(row.ts, reportData.station.type, reportData.interval),
                        y: 0
                    });
                }

            });

            if (data.length > 0) {

                datasets.push({
                    label: attribute.label,
                    borderColor: attribute.color,
                    fill: false,
                    data,
                    pointRadius: data.length > 200 ? 0 : 3,
                    pointHitRadius: 5
                });

            }


        });

        units = uniqueStrings(units);
        unitLabels = uniqueStrings(unitLabels);

        let chart = {
            type: 'line',
            data: {
                datasets
            },
            options: {
                title: {
                    display: !dense,
                    text: `${report.label}`
                },
                plugins: {
                    legend: {
                        display: <any>(dense ? false : "auto"),
                    },
                },
                scales: {
                    x: {
                        display: dense ? false : 'auto',
                        type: 'time',
                        min: <any>moment(reportData.startDate).subtract(1, timeUnit),
                        max: <any>moment(reportData.endDate).add(1, timeUnit),
                        time: {
                            unit: timeUnit,
                        },
                        title: {
                            display: true,
                            text: `Time`,
                            font: {
                                weight: 'bold',
                                size: 14,
                            }
                        },
                    },
                    y: {
                        display: dense ? false : 'auto',
                        title: {
                            display: true,
                            text: `${unitLabels.join('/')} [${units.join('/')}]`,
                            font: {
                                weight: 'bold',
                                size: 14,
                            }
                        },
                    }
                }
            }
        };

        return chart;

    }


    private handleDateOffset(date: string, type: string, interval: WeatherReportInterval) {
        if (type === null || type === '') return moment(date);
        if (type === 'tinyTag') return moment(date); //tinyTag units require no time manipulation
        if (type === 'sigFox') {
            if (interval === WeatherReportInterval.DAILY || interval === WeatherReportInterval.MONTHLY) return moment(date);
            return moment(date + "Z").format('YYYY-MM-DD HH:mm:ss'); //sigFox timestamps are in UTC and require an offset
        }
        return moment(date);
    }
}
