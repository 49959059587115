export interface ReportTheme {
    id: string;
    label: string;

    sectionTitleBackground: string;
    sectionTitleColor: string;
    subsectionTitleBackground: string;
    subsectionTitleColor: string;
    borderColor: string;

    harvestColors: string[][];
    plantColors: string[][];
}

export const COLORS = {
    red: [
        "#B71C1C", // 900
        "#C62828", // 800
        "#D32F2F", // 700
        "#E53935", // 600
        "#F44336", // 500
        "#EF5350", // 400
        "#E57373", // 300
        "#EF9A9A", // 200
        "#FFCDD2", // 100
    ],
    pink: [
        "#880E4F", // 900
        "#AD1457", // 800
        "#C2185B", // 700
        "#D81B60", // 600
        "#E91E63", // 500
        "#EC407A", // 400
        "#F06292", // 300
        "#F48FB1", // 200
        "#F8BBD0", // 100
    ],
    purple: [
        "#4A148C", // 900
        "#6A1B9A", // 800
        "#7B1FA2", // 700
        "#8E24AA", // 600
        "#9C27B0", // 500
        "#AB47BC", // 400
        "#BA68C8", // 300
        "#CE93D8", // 200
        "#E1BEE7", // 100
    ],
    deep_purple: [
        "#311B92", // 900
        "#4527A0", // 800
        "#512DA8", // 700
        "#5E35B1", // 600
        "#673AB7", // 500
        "#7E57C2", // 400
        "#9575CD", // 300
        "#B39DDB", // 200
        "#D1C4E9", // 100
    ],
    indigo: [
        "#1A237E", // 900
        "#283593", // 800
        "#303F9F", // 700
        "#3949AB", // 600
        "#3F51B5", // 500
        "#5C6BC0", // 400
        "#7986CB", // 300
        "#9FA8DA", // 200
        "#C5CAE9", // 100
    ],
    blue: [
        "#0D47A1", // 900
        "#1565C0", // 800
        "#1976D2", // 700
        "#1E88E5", // 600
        "#2196F3", // 500
        "#42A5F5", // 400
        "#64B5F6", // 300
        "#90CAF9", // 200
        "#BBDEFB", // 100
    ],
    light_blue: [
        "#01579B", // 900
        "#0277BD", // 800
        "#0288D1", // 700
        "#039BE5", // 600
        "#03A9F4", // 500
        "#29B6F6", // 400
        "#4FC3F7", // 300
        "#81D4FA", // 200
        "#B3E5FC", // 100
    ],
    cyan: [
        "#006064", // 900
        "#00838F", // 800
        "#0097A7", // 700
        "#00ACC1", // 600
        "#00BCD4", // 500
        "#26C6DA", // 400
        "#4DD0E1", // 300
        "#80DEEA", // 200
        "#B2EBF2", // 100
    ],
    teal: [
        "#004D40", // 900
        "#00695C", // 800
        "#00796B", // 700
        "#00897B", // 600
        "#009688", // 500
        "#26A69A", // 400
        "#4DB6AC", // 300
        "#80CBC4", // 200
        "#B2DFDB", // 100
    ],
    green: [
        "#1B5E20", // 900
        "#2E7D32", // 800
        "#388E3C", // 700
        "#43A047", // 600
        "#4CAF50", // 500
        "#66BB6A", // 400
        "#81C784", // 300
        "#A5D6A7", // 200
        "#C8E6C9", // 100
    ],
    light_green: [
        "#33691E", // 900
        "#558B2F", // 800
        "#689F38", // 700
        "#7CB342", // 600
        "#8BC34A", // 500
        "#9CCC65", // 400
        "#AED581", // 300
        "#C5E1A5", // 200
        "#DCEDC8", // 100
    ],
    lime: [
        "#827717", // 900
        "#9E9D24", // 800
        "#AFB42B", // 700
        "#C0CA33", // 600
        "#CDDC39", // 500
        "#D4E157", // 400
        "#DCE775", // 300
        "#E6EE9C", // 200
        "#F0F4C3", // 100
    ],
    yellow: [
        "#F57F17", // 900
        "#F9A825", // 800
        "#FBC02D", // 700
        "#FDD835", // 600
        "#FFEB3B", // 500
        "#FFEE58", // 400
        "#FFF176", // 300
        "#FFF59D", // 200
        "#FFF9C4", // 100
    ],
    amber: [
        "#FF6F00", // 900
        "#FF8F00", // 800
        "#FFA000", // 700
        "#FFB300", // 600
        "#FFC107", // 500
        "#FFCA28", // 400
        "#FFD54F", // 300
        "#FFE082", // 200
        "#FFECB3", // 100
    ],
    orange: [
        "#E65100", // 900
        "#EF6C00", // 800
        "#F57C00", // 700
        "#FB8C00", // 600
        "#FF9800", // 500
        "#FFA726", // 400
        "#FFB74D", // 300
        "#FFCC80", // 200
        "#FFE0B2", // 100
    ],
    deep_orange: [
        "#BF360C", // 900
        "#D84315", // 800
        "#E64A19", // 700
        "#F4511E", // 600
        "#FF5722", // 500
        "#FF7043", // 400
        "#FF8A65", // 300
        "#FFAB91", // 200
        "#FFCCBC", // 100
    ],
    brown: [
        "#3E2723", // 900
        "#4E342E", // 800
        "#5D4037", // 700
        "#6D4C41", // 600
        "#795548", // 500
        "#8D6E63", // 400
        "#A1887F", // 300
        "#BCAAA4", // 200
        "#D7CCC8", // 100
    ],
    gray: [
        "#212121", // 900
        "#424242", // 800
        "#616161", // 700
        "#757575", // 600
        "#9E9E9E", // 500
        "#BDBDBD", // 400
        "#E0E0E0", // 300
        "#EEEEEE", // 200
        "#F5F5F5", // 100
    ],
    blue_gray: [
        "#CFD8DC", // 100
        "#B0BEC5", // 200
        "#90A4AE", // 300
        "#78909C", // 400
        "#607D8B", // 500
        "#546E7A", // 600
        "#455A64", // 700
        "#37474F", // 800
        "#263238", // 900
    ],
    provar_gray: [ // base: #808285
        "#333435",
        "#4C4E4F",
        "#66686A",
        "#808285", // b
        "#999B9D",
        "#B2B4B5",
        "#CCCDCE",
        "#E5E6E6",
        "#F2F3F3",
    ],
    provar_green: [ // base: #8cb30b
        "#384804",
        "#546B06",
        "#708F08",
        "#8CB30B", // b
        "#A3C23B",
        "#BAD16C",
        "#DCE8B5",
        "#D1E09D",
        "#E8EFCE",
    ],
    prisma_slate: [
        "#0f172a", // 900
        "#1e293b", // 800
        "#334155", // 700
        "#475569", // 600
        "#64748b", // 500
        "#94a3b8", // 400
        "#cbd5e1", // 300
        "#e2e8f0", // 200
        "#f1f5f9", // 100
    ],
    prisma_neutral: [
        "#171717", // 900
        "#262626", // 800
        "#404040", // 700
        "#525252", // 600
        "#737373", // 500
        "#a3a3a3", // 400
        "#d4d4d4", // 300
        "#e5e5e5", // 200
        "#f5f5f5", // 100
    ],
    prisma_red: [
        "#7f1d1d", // 900
        "#991b1b", // 800
        "#b91c1c", // 700
        "#dc2626", // 600
        "#ef4444", // 500
        "#f87171", // 400
        "#fca5a5", // 300
        "#fecaca", // 200
        "#fee2e2", // 100
    ],
    prisma_orange: [
        "#7c2d12", // 900
        "#9a3412", // 800
        "#c2410c", // 700
        "#ea580c", // 600
        "#f97316", // 500
        "#fb923c", // 400
        "#fdba74", // 300
        "#fed7aa", // 200
        "#ffedd5", // 100
    ],
    prisma_amber: [
        "#78350f", // 900
        "#92400e", // 800
        "#b45309", // 700
        "#d97706", // 600
        "#f59e0b", // 500
        "#fbbf24", // 400
        "#fde047", // 300
        "#fef08a", // 200
        "#fef3c7", // 100
    ],
    prisma_yellow: [
        "#713f12", // 900
        "#854d0e", // 800
        "#a16207", // 700
        "#ca8a04", // 600
        "#eab308", // 500
        "#facc15", // 400
        "#fde047", // 300
        "#fef08a", // 200
        "#fef9c3", // 100
    ],
    prisma_lime: [
        "#365314", // 900
        "#3f6212", // 800
        "#4d7c0f", // 700
        "#65a30d", // 600
        "#84cc16", // 500
        "#a3e635", // 400
        "#bef264", // 300
        "#d9f99d", // 200
        "#ecfccb", // 100
    ],
    prisma_green: [
        "#14532d", // 900
        "#166534", // 800
        "#15803d", // 700
        "#16a34a", // 600
        "#22c55e", // 500
        "#4ade80", // 400
        "#86efac", // 300
        "#bbf7d0", // 200
        "#dcfce7", // 100
    ],
    prisma_emerald: [
        "#064e3b", // 900
        "#065f46", // 800
        "#047857", // 700
        "#059669", // 600
        "#10b981", // 500
        "#34d399", // 400
        "#6ee7b7", // 300
        "#a7f3d0", // 200
        "#d1fae5", // 100
    ],
    prisma_teal: [
        "#134e4a", // 900
        "#115e59", // 800
        "#0f766e", // 700
        "#0d9488", // 600
        "#14b8a6", // 500
        "#2dd4bf", // 400
        "#5eead4", // 300
        "#99f6e4", // 200
        "#ccfbf1", // 100
    ],
    prisma_cyan: [
        "#164e63", // 900
        "#155e75", // 800
        "#0e7490", // 700
        "#0891b2", // 600
        "#06b6d4", // 500
        "#22d3ee", // 400
        "#67e8f9", // 300
        "#a5f3fc", // 200
        "#cffafe", // 100
    ],
    prisma_sky: [
        "#0c4a6e", // 900
        "#075985", // 800
        "#0369a1", // 700
        "#0284c7", // 600
        "#0ea5e9", // 500
        "#38bdf8", // 400
        "#7dd3fc", // 300
        "#bae6fd", // 200
        "#e0f2fe", // 100
    ],
    prisma_blue: [
        "#1e3a8a", // 900
        "#1e40af", // 800
        "#1d4ed8", // 700
        "#2563eb", // 600
        "#3b82f6", // 500
        "#60a5fa", // 400
        "#93c5fd", // 300
        "#bfdbfe", // 200
        "#dbeafe", // 100
    ],
    prisma_indigo: [
        "#312e81", // 900
        "#3730a3", // 800
        "#4338ca", // 700
        "#4f46e5", // 600
        "#6366f1", // 500
        "#818cf8", // 400
        "#a5b4fc", // 300
        "#c7d2fe", // 200
        "#e0e7ff", // 100
    ],
    prisma_violet: [
        "#4c1d95", // 900
        "#5b21b6", // 800
        "#6d28d9", // 700
        "#7c3aed", // 600
        "#8b5cf6", // 500
        "#a78bfa", // 400
        "#c4b5fd", // 300
        "#ddd6fe", // 200
        "#ede9fe", // 100
    ],
    prisma_purple: [
        "#581c87", // 900
        "#6b21a8", // 800
        "#7e22ce", // 700
        "#9333ea", // 600
        "#a855f7", // 500
        "#c084fc", // 400
        "#d8b4fe", // 300
        "#e9d5ff", // 200
        "#f3e8ff", // 100
    ],
    prisma_fuchsia: [
        "#701a75", // 900
        "#86198f", // 800
        "#a21caf", // 700
        "#c026d3", // 600
        "#d946ef", // 500
        "#e879f9", // 400
        "#f0abfc", // 300
        "#f5d0fe", // 200
        "#fae8ff", // 100
    ],
    prisma_pink: [
        "#831843", // 900
        "#9d174d", // 800
        "#be185d", // 700
        "#db2777", // 600
        "#ec4899", // 500
        "#f472b6", // 400
        "#f9a8d4", // 300
        "#fbcfe8", // 200
        "#fce7f3", // 100
    ],
    prisma_rose: [
        "#881337", // 900
        "#9f1239", // 800
        "#be123c", // 700
        "#e11d48", // 600
        "#f43f5e", // 500
        "#fb7185", // 400
        "#fda4af", // 300
        "#fecdd3", // 200
        "#ffe4e6", // 100
    ],
};

export const REPORT_THEMES: ReportTheme[] = [
    {
        id: "provar",
        label: 'Pro Theme',
        sectionTitleBackground: COLORS.provar_green[2],
        sectionTitleColor: "#ffffff",
        subsectionTitleBackground: COLORS.provar_green[2],
        subsectionTitleColor: "#ffffff",
        borderColor: "#ddd",
        harvestColors: [
            COLORS.provar_green,
            COLORS.provar_gray,
            COLORS.deep_purple,
            COLORS.blue,
            COLORS.cyan,
        ],
        plantColors: [
            COLORS.pink,
            COLORS.deep_orange,
            COLORS.amber,
            COLORS.lime,
            COLORS.gray,
        ]
    },
    {
        id: "canon",
        label: 'Canon',
        sectionTitleBackground: COLORS.blue_gray[2],
        sectionTitleColor: "#ffffff",
        subsectionTitleBackground: COLORS.blue_gray[2],
        subsectionTitleColor: "#ffffff",
        borderColor: "#ddd",
        harvestColors: [
            COLORS.orange,
            COLORS.red,
            COLORS.yellow,
            COLORS.brown,
            COLORS.blue_gray,
        ],
        plantColors: [
            COLORS.light_green,
            COLORS.teal,
            COLORS.light_blue,
            COLORS.indigo,
            COLORS.purple,
        ]
    },
    {
        id: "prisma",
        label: 'Prisma',
        sectionTitleBackground: COLORS.prisma_blue[8],
        sectionTitleColor: "#ffffff",
        subsectionTitleBackground: COLORS.prisma_blue[8],
        subsectionTitleColor: "#ffffff",
        borderColor: '#ddd',
        harvestColors: [
            COLORS.prisma_rose,
            COLORS.prisma_sky,
            COLORS.prisma_yellow,
            COLORS.prisma_emerald,
            COLORS.prisma_pink,
            COLORS.prisma_fuchsia,
            COLORS.prisma_blue,
            COLORS.prisma_lime,
            COLORS.prisma_neutral,
            COLORS.prisma_purple,
            COLORS.prisma_cyan,
            COLORS.prisma_green,
            COLORS.prisma_amber,
            COLORS.prisma_violet,
            COLORS.prisma_teal,
            COLORS.prisma_orange,
            COLORS.prisma_red,
            COLORS.prisma_slate,
            COLORS.prisma_indigo,
        ],
        plantColors: [
            COLORS.prisma_indigo,
            COLORS.prisma_slate,
            COLORS.prisma_red,
            COLORS.prisma_orange,
            COLORS.prisma_teal,
            COLORS.prisma_violet,
            COLORS.prisma_amber,
            COLORS.prisma_green,
            COLORS.prisma_cyan,
            COLORS.prisma_purple,
            COLORS.prisma_neutral,
            COLORS.prisma_lime,
            COLORS.prisma_blue,
            COLORS.prisma_fuchsia,
            COLORS.prisma_pink,
            COLORS.prisma_emerald,
            COLORS.prisma_yellow,
            COLORS.prisma_sky,
            COLORS.prisma_rose
        ]
    },
];

export class ReportThemeCompiler {

    constructor(private _options: ReportTheme) {}

    getSeriesShade(sampleType: string, sampleIndex: number, evalIndex: number): string {

        let colors: string[][] = [['#000000']];

        if (sampleType === 'harvest') {
            colors = this._options.harvestColors;
        } else {
            colors = this._options.plantColors;
        }

        const ci = colors[sampleIndex % colors.length];
        return ci[(evalIndex*2) % ci.length];
    }
}
