<!-- LETTERHEAD --->
<!-- Below check is done to ensure that old letterheads are still visible if a new letterhead is not sets -->
<div class="report-letterhead" *ngIf="letterheadRef && !letterhead">
    <img class="report-letterhead-img" [src]="letterheadRef | srcFileRef:{format:'png'}" alt="">
</div>
<div class="pv-report-letterhead" *ngIf="letterhead">
    <pv-image-ref class="pv-report-letterhead-img"
        [fileRef]="letterhead.fileRef"
        [transformId]="c_fit">
    </pv-image-ref>
</div>
<!-- LETTERHEAD END --->


<!-- HEADER -->
<header class="report-header">
    <h1 class="report-title">{{ title }}</h1>

    <div *ngIf="toggleDescription">
        <h1 class="report-desc">{{ description }}</h1>
    </div>

    <div class="cultivar-image" *ngIf="remarkImageOptions['header']?.length">
        <ng-container *ngFor="let image of remarkImageOptions['header']">
            <pv-image-ref
                class="cultivar-image-thumb"
                [fileRef]="image.fileRef"
                [transformId]="imageTransformId('s')"
                (click)="openRemarkImages(image, remarkImageOptions['header'])">
            </pv-image-ref>
        </ng-container>
    </div>
    <pv-report-remark class="report-section-remark"
                    sectionId="header"
                    [editable]="editable"
                    [text]="mappedRemarks['header']"
                    [documents]="remarkDocuments['header']"
                    [images]="remarkImageOptions['header']">>
    </pv-report-remark>
</header>
<!-- HEADER END --->

<!--SECTIONS START-->
<div class="sections-wrapper-div">
    <!-- REPORT SECTION: SPECS -->
    <section class="report-section report-section-specs" *ngIf="legends" [ngClass]="{'order-1': sequence.Specifications==1,
                                                                     'order-2': sequence.Specifications==2,
                                                                     'order-3': sequence.Specifications==3,
                                                                     'order-4': sequence.Specifications==4,
                                                                     'order-5': sequence.Specifications==5,
                                                                     'order-6': sequence.Specifications==6,
                                                                     'order-7': sequence.Specifications==7,
                                                                     'order-8': sequence.Specifications==8,
                                                                     'order-9': sequence.Specifications==9,
                                                                     'order-10': sequence.Specifications==10}"
                                                                    >
        <div class="report-section-intro">
            <h3 class="report-section-header">
                Specifications
            </h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['specifications']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['specifications']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['specifications'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="specifications"
                    [editable]="editable"
                    [text]="mappedRemarks['specifications']"
                    [documents]="remarkDocuments['specifications']"
                    [images]="remarkImageOptions['specifications']">
            </pv-report-remark>
        </div>

        <!-- Report Specifications -->
        <div class="pv-report-legend">
            <div class="specification-wrapper" *ngFor="let legend of legends; trackBy: trackById">
                <div class="sample-specs" *ngIf="legend.sample as sample" [ngSwitch]="sample.type">
                    <div class="sample-image">

                        <div *ngIf="sample.primaryImage;then image else icon"></div>

                        <!-- Image -->
                        <ng-template #image>
                            <pv-image-ref
                                class="sample-image-thumb"
                                *ngIf="sample.primaryImage as image"
                                [fileRef]="image.fileRef"
                                transformId="thumb_300"
                                (click)="handleSpecificationImage(sample, image)">
                            </pv-image-ref>
                        </ng-template>

                        <!-- Icon -->
                        <ng-template #icon>
                            <mat-icon
                                [svgIcon]="'common:' + sample.scionCultivar.cropId"
                                class="mat-24 crop-image"
                                (click)="handleSpecificationImage(sample)">
                            </mat-icon>
                        </ng-template>
                    </div>

                    <div class="sample-detail" >
                        <table class="legend-table">
                            <tbody>
                                <tr>
                                    <th><mat-icon matTooltip="Crop Icon" [svgIcon]="'common:'+sample?.scionCultivar?.cropId"></mat-icon></th>
                                    <td>
                                        <h3 class="sample-title" matTooltip="{{ sample.code }}">{{ sample.scionCultivar | descCultivar }}</h3>
                                        <p class="sample-label">{{ sample.label }}</p>
                                        <small *ngIf="sample.description">{{ sample.description }}</small>
                                    </td>
                                </tr>

                                <!-- Harvested Date -->
                                <tr *ngSwitchCase="'harvest'">
                                    <th><mat-icon matTooltip="Planted/Harvested Date">schedule</mat-icon></th>
                                    <td>{{ sample.birthDate | amDateFormat:'[Week] W' }},&nbsp;{{ sample.birthDate | amDateFormat:'ll' }}</td>
                                </tr>

                                <!-- Planted Date -->
                                <tr *ngSwitchCase="'plant'">
                                    <th><mat-icon matTooltip="Date Planted">park</mat-icon></th>
                                    <td>{{ sample.birthDate | amDateFormat:'ll' }}</td>
                                </tr>

                                <!-- Destroyed Date -->
                                <tr *ngIf="sample.deathDate">
                                    <th><mat-icon matTooltip="Grubbed/Destroyed Date">auto_delete</mat-icon></th>
                                    <td>{{ sample.deathDate}}</td>
                                </tr>

                                <!-- Rootstock -->
                                <tr *ngIf="sample.rootstockCultivar">
                                    <th><mat-icon matTooltip="Rootstock Cultivar" svgIcon="common:flat_rootstock"></mat-icon></th>
                                    <td>{{ sample.rootstockCultivar | descCultivar }}</td>
                                </tr>

                                <!-- Site -->
                                <tr *ngIf="sample.site">
                                    <th><mat-icon matTooltip="Site">place</mat-icon></th>
                                    <td>{{ sample.site.name }}, {{ sample.site.block }}</td>
                                </tr>

                                <!-- Sample Size -->
                                <tr *ngIf="sample.size">
                                    <th><mat-icon matTooltip="Sample Size">tag</mat-icon></th>
                                    <td>{{ sample.size }}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="sample-series" *ngIf="legend.sample as sample" [ngSwitch]="sample.type">
                    <table class="legend-table">
                        <tbody class="legend-table-body">
                            <tr *ngFor="let serie of legend.series; trackBy: trackByKey"
                                class="legend-table-row">
                                <td [style.backgroundColor]="serie.color" class="series-ref">
                                    {{ serie.ref }}
                                </td>
                                <td class="sample-storage">
                                    <strong>{{ serie.evalLabel ? serie.evalLabel : '' }},&nbsp;</strong>
                                    <span *ngIf="sample.type == 'harvest'">Week&nbsp;</span>
                                    <time *ngIf="serie.startDate" matTooltip="{{ serie.startDate | date:'d LLL, yyyy' }}">{{ sample.type == 'harvest' ? (serie.startDate | date:'w') : (serie.startDate | date:'yyyy') }}</time>

                                    <ul *ngIf="showStorageRegime && sample.type == 'harvest'">
                                        <li *ngFor="let storage of serie.storageRegime">
                                            {{ storage.duration }}&nbsp;@&nbsp;{{ storage.temp }}&#8451;&nbsp;
                                            <span *ngIf="storage.ca">(CA)</span>&nbsp;
                                            <span *ngIf="storage.treatment?.length">(AT)</span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="cultivar-image" *ngIf="remarkImageOptions['legend']?.length">
            <ng-container *ngFor="let image of remarkImageOptions['legend']">
                <pv-image-ref
                    class="cultivar-image-thumb"
                    [fileRef]="image.fileRef"
                    [transformId]="imageTransformId('s')"
                    (click)="openRemarkImages(image, remarkImageOptions['legend'])">
                </pv-image-ref>
            </ng-container>
        </div>
        <pv-report-remark class="report-section-remark"
            sectionId="legend"
            [editable]="editable"
            [text]="mappedRemarks['legend']"
            [documents]="remarkDocuments['legend']"
            [images]="remarkImageOptions['legend']">
        </pv-report-remark>

    </section>

    <!-- END REPORT SECTION: SPECS -->


    <!-- REPORT SECTION: TIMELINE -->
    <section class="report-section report-section-timeline" *ngIf="timelineReport" [ngClass]="{'order-1': sequence.Timeline==1,
                                                                     'order-2': sequence.Timeline==2,
                                                                     'order-3': sequence.Timeline==3,
                                                                     'order-4': sequence.Timeline==4,
                                                                     'order-5': sequence.Timeline==5,
                                                                     'order-6': sequence.Timeline==6,
                                                                     'order-7': sequence.Timeline==7,
                                                                     'order-8': sequence.Timeline==8,
                                                                     'order-9': sequence.Timeline==9,
                                                                     'order-10': sequence.Timeline==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">Timeline</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['timeline.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['timeline.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['timeline.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="timeline.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['timeline.intro']"
                    [documents]="remarkDocuments['timeline.intro']"
                    [images]="remarkImageOptions['timeline.intro']">
            </pv-report-remark>
        </div>

        <pv-timeline
            *ngFor="let timeline of timelineReport.timelines; index as i; trackBy: trackById"
            [start]="timelineReport.timelinesOptions[i].startDate"
            [end]="timelineReport.timelinesOptions[i].endDate"
            [idKeys]="timeline.idKeys"
            [formControl]="formControlPassthrough"
            [lowestStart]="lowestStartDate"
            [highestEnd]="highestEndDate">
            <pv-timeline-group *ngFor="let group of timeline.groups; trackBy: trackById" [content]="group.title">
                <pv-timeline-item *ngFor="let item of group.items; trackBy: trackById"
                        [type]="item.type"
                        [content]="item.title"
                        [color]="item.color"
                        [start]="item.startDate"
                        [end]="item.endDate">
                </pv-timeline-item>
            </pv-timeline-group>
        </pv-timeline>

    </section>
    <!-- END REPORT SECTION: TIMELINE -->


    <!-- REPORT SECTION: WEATHER REPORTS -->
    <section class="report-section report-section-weather" *ngIf="weatherReports" [ngClass]="{'order-1': sequence.Weather==1,
                                                                     'order-2': sequence.Weather==2,
                                                                     'order-3': sequence.Weather==3,
                                                                     'order-4': sequence.Weather==4,
                                                                     'order-5': sequence.Weather==5,
                                                                     'order-6': sequence.Weather==6,
                                                                     'order-7': sequence.Weather==7,
                                                                     'order-8': sequence.Weather==8,
                                                                     'order-9': sequence.Weather==9,
                                                                     'order-10': sequence.Weather==10}">
        <div class="report-section-intro">
            <h3 class="report-section-header">Weather Reports</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['weatherReports.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['weatherReports.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['weatherReports.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="weatherReports.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['weatherReports.intro']"
                    [documents]="remarkDocuments['weatherReports.intro']"
                    [images]="remarkImageOptions['weatherReports.intro']">
            </pv-report-remark>
            <p class="report-section-empty" *ngIf="!weatherReports.length">
                No weather reports added.
            </p>
        </div>

        <div class="weather-graph-container">
            <div class="report-subsection" *ngFor="let report of weatherReports; trackBy: trackByKey">

                <h4 class="report-subsection-header">
                    <mat-icon *ngIf="editable" class="edit-icon" (click)="editWeatherReport(report)">settings</mat-icon>
                    {{ report.title }}
                    <small>
                        <span *ngFor="let attribute of report.options.attributes; last as isLast">
                            {{ attribute.label }} [{{ attribute.unitLabel }}, {{ attribute.unit }}]{{ isLast ? '' : ',' }}
                        </span>
                    </small>
                </h4>

                <pv-legacy-chart [type]="report.chart.type"
                        [data]="report.chart.data"
                        [options]="report.chart.options"
                        [devicePixelRatio]="print ? printChartRatio : 1"
                        [chartLabel]="report.label">
                </pv-legacy-chart>

            </div>
        </div>
    </section>
    <!-- REPORT SECTION: WEATHER REPORTS END -->

    <!-- REPORT SECTION: TABLE REPORTS -->
    <section class="report-section report-section-tables" *ngIf="tableReports" [ngClass]="{'order-1': sequence.Tables==1,
                                                                     'order-2': sequence.Tables==2,
                                                                     'order-3': sequence.Tables==3,
                                                                     'order-4': sequence.Tables==4,
                                                                     'order-5': sequence.Tables==5,
                                                                     'order-6': sequence.Tables==6,
                                                                     'order-7': sequence.Tables==7,
                                                                     'order-8': sequence.Tables==8,
                                                                     'order-9': sequence.Tables==9,
                                                                     'order-10': sequence.Tables==10}">
        <div class="report-section-intro">
            <h3 class="report-section-header">Tabulated Reports</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['tableReports.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['tableReports.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['tableReports.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="tableReports.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['tableReports.intro']"
                    [documents]="remarkDocuments['tableReports.intro']"
                    [images]="remarkImageOptions['tableReports.intro']">
            </pv-report-remark>
            <p class="report-section-empty" *ngIf="!tableReports.length">
                No table reports added.
            </p>
        </div>
        <div class="report-subsection" *ngFor="let report of tableReports; trackBy: trackByKey; let i = index">
            <div class="cultivar-image" *ngIf="remarkImageOptions['tableReports.customTable.' + report.id]?.length">
                <ng-container *ngFor="let image of remarkImageOptions['tableReports.customTable.' + report.id]">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['tableReports.customTable.' + report.id])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <h4 class="report-subsection-header">
                {{ report.title }} {{ report.tableOptions?.isAggregate ? "(Aggregate)" :  report.tableOptions?.isSpread ? "(Spread)" : "" }}
                <small>
                    <span>{{ (report.description) ? report.description : ( report.tableOptions?.columns | descTableReport ) }}</span>
                </small>
            </h4>
            <pv-report-remark class="report-section-remark report-tables"
                [sectionId]="'tableReports.customTable.' + report.id"
                [editable]="editable"
                [text]="mappedRemarks['tableReports.customTable.' + report.id]"
                [documents]="remarkDocuments['tableReports.customTable.' + report.id]"
                [images]="remarkImageOptions['tableReports.customTable.' + report.id]">
            </pv-report-remark>

            <table mat-table class="report-table" style="table-layout: fixed;" [dataSource]="report.tableData" *ngIf="report.tableData">

                <ng-container matColumnDef="series">
                    <th mat-header-cell *matHeaderCellDef style="width: 10px;"></th>
                    <td mat-cell *matCellDef="let object; let i = index" [style.backgroundColor]="object.summary ? getRowColour(object.color) : ''">
                        <i
                            *ngIf="!report.tableOptions?.isAggregate && !report.tableOptions?.isSpread"
                            [matTooltip]="(report) | descEval:object.ref:report.tooltipInfo?.cultivar:report.tooltipInfo[i]?.evalu"
                            [matTooltipClass]="'mat-tooltip-custom'"
                        class="si"
                        [style.backgroundColor]="object.color">{{ !object.summary ? object.ref : ""}}</i>&nbsp;
                        <i
                        *ngIf="report.tableOptions?.isAggregate || report.tableOptions?.isSpread"
                        class="si" [style.backgroundColor]="object.color">{{ !object.summary ? object.ref : ""}}</i>&nbsp;
                    </td>
                </ng-container>

                <ng-container *ngFor="let column of report.columnDefs" matColumnDef="{{ column.id }}">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center;" style.width="{{column.itemDef?.width}}px">
                        {{ column.label && column.unit ? column.label + ' [' + column.unit + ']' : column.itemDef?.label && column.itemDef?.unit ? column.itemDef?.label + ' [' + column.itemDef?.unit + ']' :  column.itemDef?.label }}
                    </th>
                    <td mat-cell *matCellDef="let object" style="text-align: center;" [style.backgroundColor]="object.summary ? getRowColour(object.color) : ''">
                        <ng-container [ngSwitch]="column.type">
                            <ng-container *ngSwitchCase="tableReportColumnType.Characteristic">
                                <i

                                class="si" [style.backgroundColor]="object[column.id]" *ngIf="column.itemDef?.type === 'color' && object[column.id]"></i>
                                {{ object[column.id] | labelCharValue:column.itemDef?.id : object[column.id+'.rescaled'] : true : true }}
                            </ng-container>
                            <ng-container *ngSwitchCase="tableReportColumnType.Measurement">
                                <ng-container *ngIf="object[column.id+'.calculated'];else defaultMeasure">
                                    <ng-container *ngIf="!object[column.id+'.empty'];else noPicks">
                                        {{ object[column.id] | number:'1.1-2' }}{{object[column.id+'.unit']}} | {{ object[column.id+'.calculated'] }}{{object[column.id+'.calculated.unit']}}
                                    </ng-container>
                                    <ng-template #noPicks>
                                        <b>{{ object[column.id+'.empty'] }}</b>
                                    </ng-template>
                                </ng-container>
                                <ng-template #defaultMeasure>{{ object[column.id] | number:'1.1-2' }}</ng-template>
                            </ng-container>
                            <ng-container *ngSwitchCase="tableReportColumnType.Calculation">
                                {{ object[column.id] | number:'1.1-2' }}
                            </ng-container>
                            <ng-container *ngSwitchCase="tableReportColumnType.Index">
                                {{ object[column.id] | number:'1.1-2' }}
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <ng-container [ngSwitch]="column.id">
                                    <ng-container *ngSwitchCase="'prop_eval_notes'">
                                        {{ object[column.id] | extractMessage }}
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{ object[column.id] }}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="report['cols'] sticky: false"></tr>
                <tr mat-row *matRowDef="let row; columns: report['cols'];" [ngStyle]="{'background': row.sampleGroupTag == '1' || row.sampleGroupTag == null  ? 'rgba(200, 200, 200, 0.3)' : 'rgba(255, 255, 255, 0)'}"></tr>
            </table>
        </div>
    </section>
    <!-- REPORT SECTION: TABLE REPORTS END -->

    <!-- REPORT SECTION: INFO SHEET -->
    <section class="report-section report-section-infosheet" *ngIf="infoSheetReport" [ngClass]="{'order-1': sequence.InfoSheet==1,
                                                                                                'order-2': sequence.InfoSheet==2,
                                                                                                'order-3': sequence.InfoSheet==3,
                                                                                                'order-4': sequence.InfoSheet==4,
                                                                                                'order-5': sequence.InfoSheet==5,
                                                                                                'order-6': sequence.InfoSheet==6,
                                                                                                'order-7': sequence.InfoSheet==7,
                                                                                                'order-8': sequence.InfoSheet==8,
                                                                                                'order-9': sequence.InfoSheet==9,
                                                                                                'order-10': sequence.InfoSheet==10}">
        <div class="report-section-intro">
            <h3 class="report-section-header">Info Sheet</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['infosheet.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['infosheet.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['infosheet.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="infosheet.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['infosheet.intro']"
                    [documents]="remarkDocuments['infosheet.intro']"
                    [images]="remarkImageOptions['infosheet.intro']">
            </pv-report-remark>
            <h2 *ngIf="infoSheetReport.options.primaryCultivar" class="infosheet-title"><span>{{infoSheetReport.options.primaryCultivar.commonName}}</span></h2>
            <div style="overflow: hidden;"
                *ngIf="infoSheetReport.options.primaryCultivar
                && (infoSheetReport.options.includeSamples.length > 0)
                && (infoSheetReport.options.showSampleOrigin)">
                <p style="padding-bottom: -42px"><strong>Aggregated Samples:</strong><p>
                <div style="display:inline-flex; width: 25%; flex-direction: row; flex-wrap: wrap;"
                    *ngFor="let sample of infoSheetReport.options.includeSamples">
                    <h5>
                        {{ sample.data.scionCultivar ? (('Scion Cultivar: '+sample.data.scionCultivar.commonName ) | titlecase ) : 'Scion Cultivar: No Cultivar' }} <br/>
                        {{ sample.data.rootstockCultivar ? (('Rootstock Cultivar: '+sample.data.rootstockCultivar.commonName ) | titlecase ) : 'Rootstock Cultivar: No Cultivar' }} <br/>
                        {{ sample.data.label ? (('Label: '+sample.data.label) | titlecase ) : 'Label: No Label' }} <br/>
                        {{ ('Code:  '+sample.data.code)              }} <br/>
                        {{ ('Type:  '+sample.data.type)  | titlecase }} <br/>
                        {{  'Site:  '+sample.data.site.name }} <br/>
                        {{ sample.data.evals[0]?.evalStartDate ? 'Year:  '+ (sample.data.evals[0].evalStartDate | date: 'yyyy') : 'Year: N/A'}}<br/>
                    </h5>
                </div>
            </div>
            <p class="report-section-empty" *ngIf="!infoSheetReport.options.primaryCultivar">
                No cultivar selected.
            </p>

        </div>

        <div class="pv-report-infosheet" *ngIf="infoSheetReport.options.primaryCultivar as primaryCultivar">
            <div class="infoSheet-drag-group" cdkDropListGroup>
                <div
                    cdkDropList
                    [cdkDropListData]="i"
                    cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="infoSheetDropped(0, i)"
                    *ngFor="let image of infoSheetReport.infoSheetImageOptions[0]; let i = index;" >
                    <pv-image-ref
                        class="infoSheet-drag-image"
                        [class.draggable]="editable"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId(infoSheetReport.options.imageSize)"
                        cdkDrag
                        [cdkDragData]="i"
                        (cdkDragMoved)="dragMoved($event)"
                        cdkDragBoundary=".infoSheet-drag-group">
                    </pv-image-ref>
                    <div class="overlay">
                        <div class="image-note">{{isPrimaryCultivarImage(image.key) ? 'Primary Cultivar' : 'Control Cultivar'}}</div>
                    </div>
                </div>
                <button *ngIf="editable" mat-list-item type="button" (click)="editInfoSheetImages(primaryCultivar.key, 0)" class="cultivar-image-edit no-print">
                    <span><mat-icon matListIcon>edit</mat-icon></span>
                </button>
            </div>

            <div class="cultivar-detail infosheet-columns">
                <table class="infosheet-table">
                    <tbody>
                        <tr>
                            <th>
                                <mat-icon svgIcon="common:{{ (primaryCultivar.isRootstock) ? 'flat_rootstock' : 'flat_scion' }}"></mat-icon> Cultivar
                            </th>
                            <td>
                                <h3 class="cultivar-title">{{ primaryCultivar.commonName }}</h3>
                                <h4 class="cultivar-label">{{ primaryCultivar.selectionId }}</h4>
                            </td>
                        </tr>
                        <tr *ngIf="infoSheetReport.options.controlCultivar">
                            <th>
                                <mat-icon svgIcon="common:{{ (primaryCultivar.isRootstock) ? 'flat_rootstock' : 'flat_scion' }}"></mat-icon> Control Cultivar
                            </th>
                            <td style="opacity: 0.5;">
                                <h3 class="cultivar-title">{{ infoSheetReport.options.controlCultivar.commonName }}</h3>
                            </td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{{ primaryCultivar | descCultivar }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.licensee">
                            <th>Licensee</th>
                            <td>{{ primaryCultivar.licensee }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.licensor">
                            <th>Licensor</th>
                            <td>{{ primaryCultivar.licensor }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.origin">
                            <th>Origin</th>
                            <td>{{ primaryCultivar.origin }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.salleles">
                            <th>S-alleles</th>
                            <td>{{ primaryCultivar.salleles }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.pollinators">
                            <th>Recommended Pollinators</th>
                            <td>{{ primaryCultivar.pollinators }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.licenseeAgreement">
                            <th>Licensee Testing Agreement</th>
                            <td>{{ primaryCultivar.licenseeAgreement | extractAgreement}}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.licensorAgreement">
                            <th>Licensor Testing Agreement</th>
                            <td>{{ primaryCultivar.licensorAgreement | extractAgreement }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.seedParent">
                            <th>Seed Parent</th>
                            <td>{{ primaryCultivar.seedParent }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.pollenParent">
                            <th>Pollen Parent</th>
                            <td>{{ primaryCultivar.pollenParent }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.tradeNames && primaryCultivar.tradeNames.length">
                            <th>Trade Name(s)</th>
                            <td>{{ primaryCultivar.tradeNames | implode }}</td>
                        </tr>
                        <tr *ngIf="primaryCultivar.harvestWindow">
                            <th>Harvest Window</th>
                            <td>{{ primaryCultivar.harvestWindow | extractHarvestWindow }}</td>
                        </tr>

                        <ng-container *ngFor="let group of infoSheetReport.charSummary.groups; trackBy: trackById">
                            <ng-container *ngFor="let char of group.chars; trackBy: trackById">
                                <tr *ngIf="char.values.length > 0 && (hasCharValues(char.values) || showEmptyFields)">
                                    <th>{{ char.options.label }}</th>
                                    <td *ngIf="char.values.length == 2">
                                        <span *ngIf="char.values[0].value !== null || showEmptyFields" [ngStyle]="{'opacity': char.values[0].series?.key == infoSheetReport.options.controlCultivar?.key ? 0.5 : 1}">
                                            {{ (char.values[0].value | labelCharValue:char.options.id : char.values[0].rescaled : false : true) || '-' }}
                                        </span>
                                        <br>
                                        <span *ngIf="char.values[1].value !== null || showEmptyFields" [ngStyle]="{'opacity': char.values[1].series?.key == infoSheetReport.options.controlCultivar?.key ? 0.5 : 1}">
                                            {{ (char.values[1].value | labelCharValue:char.options.id : char.values[1].rescaled : false : true) || '-' }}
                                        </span>
                                    </td>
                                    <td *ngIf="char.values.length == 1">
                                        <span *ngIf="char.values[0].series?.scionCultivarKey !== primaryCultivar.key">
                                            -
                                        </span>

                                        <br *ngIf="char.values[0].series?.scionCultivarKey !== primaryCultivar.key">
                                        <span *ngIf="char.values[0].value !== null || showEmptyFields" [ngStyle]="{'opacity': char.values[0].series?.key == infoSheetReport.options.controlCultivar?.key ? 0.5 : 1}">
                                            {{ (char.values[0].value | labelCharValue:char.options.id : char.values[0].rescaled : false : true) || '-' }}
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                    </tbody>
                </table>
            </div>

            <div class="infoSheet-drag-group" cdkDropListGroup>
                <div
                    cdkDropList
                    [cdkDropListData]="i"
                    cdkDropListOrientation="horizontal"
                    (cdkDropListDropped)="infoSheetDropped(1, i)"
                    *ngFor="let image of infoSheetReport.infoSheetImageOptions[1]; let i = index;" >
                    <pv-image-ref
                        class="infoSheet-drag-image"
                        [fileRef]="image.fileRef"
                        [class.draggable]="editable"
                        [transformId]="imageTransformId(infoSheetReport.options.imageSize)"
                        cdkDrag
                        [cdkDragData]="i"
                        (cdkDragMoved)="dragMoved($event)"
                        cdkDragBoundary=".infoSheet-drag-group">
                    </pv-image-ref>
                    <div class="overlay">
                        <div class="image-note">{{isPrimaryCultivarImage(image.key) ? 'Primary Cultivar' : 'Control Cultivar'}}</div>
                    </div>
                </div>
                <button *ngIf="editable" mat-list-item type="button" (click)="editInfoSheetImages(primaryCultivar.key, 1)" class="cultivar-image-edit no-print">
                    <span><mat-icon matListIcon>edit</mat-icon></span>
                </button>
            </div>

            <!-- INCLUDE GRAPHS -->
            <div class="infosheet-graph-container report-section report-section-measures">
                <ng-container *ngFor="let measure of infoSheetReport.measureSummary.measures; trackBy: trackById">
                    <div class="report-subsection report-subsection-measure" *ngIf="!measure.isEmpty || showEmptyFields">
                        <h4 class="report-subsection-header" [class.editable]="editable" (click)="editMeasureSummary(measure.options.id, 'infosheet', infoSheetReport)">
                            {{ measure.options.label }}
                            <mat-icon *ngIf="editable" class="edit-icon">settings</mat-icon>
                        </h4>
                        <p class="report-section-empty" *ngIf="measure.isEmpty">
                            Not enough data for measurement summary.
                        </p>
                        <pv-legacy-chart class="measure-chart"
                            *ngIf="measure.chart as chart"
                            [type]="chart.type"
                            [data]="chart.data"
                            [options]="chart.options"
                            [devicePixelRatio]="print ? printChartRatio : 1"
                            [chartLabel]="measure.options.label">
                        </pv-legacy-chart>
                    </div>
                </ng-container>
                <ng-container *ngFor="let indexReport of infoSheetReport.indexSummary.indexes; trackBy: trackById; let i = index">
                    <ng-container *ngFor="let chartIndex of [0, 1]">
                        <div class="report-subsection char-index-subsection" *ngIf="!indexReport.isEmpty || showEmptyFields">


                            <h4 class="report-subsection-header">
                                {{ indexReport.index.label }}
                                <span *ngIf="infoSheetReport.options.controlCultivar">
                                    <span style="opacity: 0.5;" *ngIf="chartIndex==1" class="report-header-right char-index-avg">
                                        {{ (indexReport.seriesScores[1]?.avg !== null) ? "|" + (indexReport.seriesScores[1]?.avg | number:'1.1-1') : ""}}
                                    </span>
                                </span>
                                <span *ngIf="chartIndex==1" class="report-header-right char-index-avg">
                                    {{ (indexReport.seriesScores[0]?.avg !== null) ? (indexReport.seriesScores[0]?.avg | number:'1.1-1') : ""}}
                                </span>
                            </h4>


                            <p class="char-index-empty" *ngIf="indexReport.isEmpty">
                                Not enough data to generate index report.
                            </p>
                            <ng-container *ngIf="indexReport.charts">
                                <pv-legacy-chart
                                    *ngIf="indexReport.charts[chartIndex] as chart"
                                    class="char-index-chart"
                                    [type]="chart.type"
                                    [data]="chart.data"
                                    [options]="chart.options"
                                    [devicePixelRatio]="print ? printChartRatio : 1"
                                    [chartLabel]="indexReport.index.label">
                                </pv-legacy-chart>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </section>
    <!-- END REPORT SECTION: INFOSHEET -->

    <!-- REPORT SECTION: CHAR SUMMARY -->
    <section class="report-section report-section-chars" *ngIf="charSummary" [class.layout-dense]="charSummary.options.layoutDense" [ngClass]="{'order-1': sequence.Characteristics==1,
                                                                                                                                                'order-2': sequence.Characteristics==2,
                                                                                                                                                'order-3': sequence.Characteristics==3,
                                                                                                                                                'order-4': sequence.Characteristics==4,
                                                                                                                                                'order-5': sequence.Characteristics==5,
                                                                                                                                                'order-6': sequence.Characteristics==6,
                                                                                                                                                'order-7': sequence.Characteristics==7,
                                                                                                                                                'order-8': sequence.Characteristics==8,
                                                                                                                                                'order-9': sequence.Characteristics==9,
                                                                                                                                                'order-10': sequence.Characteristics==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">Characteristic Summary</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['charSummary.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['charSummary.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['charSummary.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="charSummary.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['charSummary.intro']"
                    [documents]="remarkDocuments['charSummary.intro']"
                    [images]="remarkImageOptions['charSummary.intro']">
            </pv-report-remark>
            <p class="report-section-error" *ngFor="let error of charSummary.errors">{{ error }}</p>
            <p class="report-section-empty" *ngIf="charSummary.isEmpty">
                No characteristics to report.
            </p>
        </div>
        <ng-container *ngFor="let group of charSummary.groups; trackBy: trackById">
            <div *ngIf="!group.isEmpty || showEmptyFields" class="report-subsection chars-report-group">
                <h4 class="report-subsection-header">
                    {{ group.category.label }}
                </h4>
                <ng-container *ngFor="let char of group.chars; trackBy: trackById; let i = index">
                    <div *ngIf="showEmptyFields || char.values.length > 0"
                        class="char-item"
                        [class.no-value]="char.values.length === 0"
                        [ngSwitch]="char.options.type">
                        <h5 class="char-label">{{ char.options.label }}
                            <small style="float:right" *ngIf="char.options.type == 'interval'">
                                Descriptor | Score
                            </small>
                        </h5>
                        <div class="char-value-list" *ngSwitchCase="'interval'">
                            <div class="char-value interval-value" *ngFor="let value of char.values; let i = index">
                                <i
                                    [matTooltip]="(value.series) | descEval:value.series.ref:value.series.tooltipInfo?.cultivar:value.series.tooltipInfo?.evalu"
                                    [matTooltipClass]="'mat-tooltip-custom'"
                                    class="si" [style.backgroundColor]="value.series.color">{{ value.series.ref }}</i>
                                <span class="eval-label">{{ value.series.evalLabel }}</span>
                                <svg class="interval-bar" viewBox="0 0 100 1" preserveAspectRatio="none">
                                    <svg:rect x="0" y="0" width="100" height="1" class="interval-backdrop"></svg:rect>
                                    <svg:rect x="0" y="0" [style.fill]="value.series.color" [attr.width]="value.value" height="1" class="interval-bar"></svg:rect>
                                </svg>
                                <span class="interval-label">{{ value.value | labelCharValue:char.options.id : value.rescaled}}</span>
                            </div>
                        </div>
                        <div class="char-value-list" *ngSwitchCase="'nominal'">
                            <div class="char-value" *ngFor="let value of char.values; let i = index">
                                <i
                                    [matTooltip]="(value.series) | descEval:value.series.ref:value.series.tooltipInfo?.cultivar:value.series.tooltipInfo?.evalu"
                                    [matTooltipClass]="'mat-tooltip-custom'"
                                    class="si" [style.backgroundColor]="value.series.color">{{ value.series.ref }}</i>
                                <span class="eval-label">{{ value.series.evalLabel }}</span>
                                {{ value.value | labelCharValue:char.options.id : value.rescaled}}
                            </div>
                        </div>
                        <div class="char-value-list" *ngSwitchCase="'event'">
                            <div class="char-value event-value" *ngFor="let value of char.values; let i = index">
                                <i
                                    [matTooltip]="(value.series) | descEval:value.series.ref:value.series.tooltipInfo?.cultivar:value.series.tooltipInfo?.evalu"
                                    [matTooltipClass]="'mat-tooltip-custom'"
                                    class="si" [style.backgroundColor]="value.series.color">{{ value.series.ref }}</i>
                                <span class="eval-label">{{ value.series.evalLabel }}</span>
                                {{ value.value | labelCharValue:char.options.id : value.rescaled}}
                            </div>
                        </div>
                        <div class="char-value-list" *ngSwitchCase="'color'">
                            <div class="char-value color-value" *ngFor="let value of char.values; let i = index">
                                <i
                                    [matTooltip]="(value.series) | descEval:value.series.ref:value.series.tooltipInfo?.cultivar:value.series.tooltipInfo?.evalu"
                                    [matTooltipClass]="'mat-tooltip-custom'"
                                    class="si" [style.backgroundColor]="value.series.color">{{ value.series.ref }}</i>
                                <span class="eval-label">{{ value.series.evalLabel }}</span>
                                <i
                                    class="si" [style.backgroundColor]="value.value"></i>
                                {{ value.value | labelCharValue:char.options.id : value.rescaled}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="chars-report-group-clear"></div>
            </div>
        </ng-container>
    </section>
    <!-- END REPORT SECTION: CHAR SUMMARY -->

    <!-- REPORT SECTION: MEASURE SUMMARIES -->
    <section class="report-section report-section-measures" *ngIf="measureSummary" [ngClass]="{'order-1': sequence.Measurements==1,
                                                                                                'order-2': sequence.Measurements==2,
                                                                                                'order-3': sequence.Measurements==3,
                                                                                                'order-4': sequence.Measurements==4,
                                                                                                'order-5': sequence.Measurements==5,
                                                                                                'order-6': sequence.Measurements==6,
                                                                                                'order-7': sequence.Measurements==7,
                                                                                                'order-8': sequence.Measurements==8,
                                                                                                'order-9': sequence.Measurements==9,
                                                                                                'order-10': sequence.Measurements==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">Measurements</h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['measureSummary.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['measureSummary.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['measureSummary.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="measureSummary.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['measureSummary.intro']"
                    [documents]="remarkDocuments['measureSummary.intro']"
                    [images]="remarkImageOptions['measureSummary.intro']">
            </pv-report-remark>
            <p class="report-section-error" *ngFor="let error of measureSummary.errors">{{ error }}</p>
            <p class="report-section-empty" *ngIf="measureSummary.isEmpty">
                No measurements to report.
            </p>
        </div>
        <ng-container *ngFor="let measure of measureSummary.measures; trackBy: trackById">

            <div class="report-subsection report-subsection-measure" *ngIf="!measure.isEmpty || showEmptyFields">
                <!-- TODO pass in report remarks -->
                <ng-container *ngIf="measureSummary.options.showComparativeCharts && getComparativeSummary(measure.id) as summary">
                    <pv-chart
                        [summary]="summary"
                        [type]="summary.chart?.type"
                        [chartLabel]="summary.options.label"
                        [editable]="editable"
                        [samples]="_samples"
                        [overrides]="getMeasureOverrides(summary.id)"
                        (onEditChart)="editMeasureChart(summary.id, $event)"
                        [measureId]="measure.id"
                        [devicePixelRatio]="print ? printChartRatio : 1"
                        [print]="print">
                    </pv-chart>
                </ng-container>

                <ng-container *ngIf="measureSummary.options.showCharts">
                    <h4 class="report-subsection-header" [class.editable]="editable" (click)="editMeasureSummary(measure.options.id, 'measurementSummary')">
                        {{ measure.options.label }}
                        <small>Unit [{{ measure.options.unit }}]</small>
                        <mat-icon *ngIf="editable" class="edit-icon">settings</mat-icon>
                    </h4>

                    <div class="cultivar-image" *ngIf="remarkImageOptions['measureSummary.measures.' + measure.options.id]?.length">
                        <ng-container *ngFor="let image of remarkImageOptions['measureSummary.measures.' + measure.options.id]">
                            <pv-image-ref
                                class="cultivar-image-thumb"
                                [fileRef]="image.fileRef"
                                [transformId]="imageTransformId('s')"
                                (click)="openRemarkImages(image, remarkImageOptions['measureSummary.measures.' + measure.options.id])">
                            </pv-image-ref>
                        </ng-container>
                    </div>
                    <pv-report-remark class="report-section-remark"
                            [sectionId]="'measureSummary.measures.' + measure.options.id"
                            [editable]="editable"
                            [text]="mappedRemarks['measureSummary.measures.' + measure.options.id]"
                            [documents]="remarkDocuments['measureSummary.measures.' + measure.options.id]"
                            [images]="remarkImageOptions['measureSummary.measures.' + measure.options.id]">
                    </pv-report-remark>
                    <br/>
                    <p class="report-section-empty" *ngIf="measure.isEmpty">
                        Not enough data for measurement summary.
                    </p>

                    <pv-legacy-chart class="measure-chart"
                            *ngIf="measure.chart as chart"
                            [type]="chart.type"
                            [data]="chart.data"
                            [options]="chart.options"
                            [devicePixelRatio]="print ? printChartRatio : 1"
                            [chartLabel]="measure.options.label">
                    </pv-legacy-chart>
                </ng-container>

                <table class="report-table measure-stats" *ngIf="!measure.isEmpty && measure.showStats">
                    <thead>
                        <tr>
                            <th>Series</th>
                            <th>N</th>
                            <th>Med</th>
                            <th>SD</th>
                            <th>SD-</th>
                            <th>SD+</th>
                            <th>SE</th>
                            <th>Min</th>
                            <th>Max</th>
                            <th>Avg</th>
                            <th *ngIf="measure.id === 'tree_yield_eff'">Tonnes/ha</th>
                            <th *ngIf="measure.id === 'frut_acid'" >Sugar Malic:Acid</th>
                            <th *ngIf="measure.id === 'frut_acid_citric'" >Sugar Citric:Acid</th>
                            <th *ngIf="measure.id === 'bnch_amal'" >Sugar:Acid</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let stats of measure.measureTableStats; let i = index">
                            <ng-container *ngIf="stats && !stats.isSummary; else summaryRow">
                                <tr>
                                    <th><i
                                        [matTooltip]="(series[stats.statIndex]) | descEval:series[stats.statIndex].ref:series[stats.statIndex].tooltipInfo?.cultivar:series[stats.statIndex].tooltipInfo?.evalu"
                                        [matTooltipClass]="'mat-tooltip-custom'"
                                        class="si" [style.backgroundColor]="series[stats.statIndex].color">{{ series[stats.statIndex].ref }}</i>&nbsp;{{ series[stats.statIndex].label }}</th>
                                    <td>{{ stats.n }}</td>
                                    <td>{{ stats.median | number:'1.0-2' }}</td>
                                    <td>{{ stats.sd | number:'1.0-2' }}</td>
                                    <td>{{ stats.sdl | number:'1.0-2' }}</td>
                                    <td>{{ stats.sdh | number:'1.0-2' }}</td>
                                    <td>{{ stats.se | number:'1.0-2' }}</td>
                                    <td>{{ stats.min | number:'1.0-2' }}</td>
                                    <td>{{ stats.max | number:'1.0-2' }}</td>
                                    <td>{{ stats.mean | number:'1.0-2' }}</td>
                                    <td *ngIf="measure.id === 'tree_yield_eff'">{{ series[stats.statIndex].seriesTonsPerHa }}</td>
                                    <th *ngIf="measure.id === 'frut_acid'">{{ series[stats.statIndex].seriesSugarAcidRatio | number:'1.0-2' }}</th>
                                    <th *ngIf="measure.id === 'frut_acid_citric'">{{ series[stats.statIndex].seriesCitricSugarAcidRatio | number:'1.0-2' }}</th>
                                    <th *ngIf="measure.id === 'bnch_amal'">{{ series[stats.statIndex].seriesBunchSugarAcidRatio | number:'1.0-2' }}</th>
                                </tr>
                            </ng-container>
                            <ng-template #summaryRow>
                                <tr *ngIf="stats" [style.backgroundColor]="stats.summaryColor">
                                    <th><strong>{{ series[stats.statIndex] ? series[stats.statIndex]?.sampleLabel : '' }}</strong></th>
                                    <td>{{ stats.n }}</td>
                                    <td>{{ stats.median | number:'1.0-2' }}</td>
                                    <td>{{ stats.sd | number:'1.0-2' }}</td>
                                    <td>{{ stats.sdl | number:'1.0-2' }}</td>
                                    <td>{{ stats.sdh | number:'1.0-2' }}</td>
                                    <td>{{ stats.se | number:'1.0-2' }}</td>
                                    <td>{{ stats.min | number:'1.0-2' }}</td>
                                    <td>{{ stats.max | number:'1.0-2' }}</td>
                                    <td>{{ stats.mean | number:'1.0-2' }}</td>
                                    <td *ngIf="measure.id === 'tree_yield_eff'">{{ stats.calculatedStats['tree_yield_eff'] }}</td>
                                    <th *ngIf="measure.id === 'frut_acid'">{{ stats.calculatedStats['frut_acid'] | number:'1.0-2' }}</th>
                                    <th *ngIf="measure.id === 'frut_acid_citric'">{{ stats.calculatedStats['frut_acid_citric'] | number:'1.0-2' }}</th>
                                    <th *ngIf="measure.id === 'bnch_amal'">{{ stats.calculatedStats['bnch_amal'] | number:'1.0-2' }}</th>
                                </tr>
                            </ng-template>
                        </ng-container>
                        <tr *ngIf="measure.showAverage">
                            <th></th>
                            <th>{{ measure.allStats.n }}</th>
                            <th>{{ measure.allStats.median | number:'1.0-2' }}</th>
                            <th>{{ measure.allStats.sd | number:'1.0-2' }}</th>
                            <th>{{ measure.allStats.sdl | number:'1.0-2' }}</th>
                            <th>{{ measure.allStats.sdh | number:'1.0-2' }}</th>
                            <td>{{ measure.allStats.se | number:'1.0-2' }}</td>
                            <th>{{ measure.allStats.min | number:'1.0-2' }}</th>
                            <th>{{ measure.allStats.max | number:'1.0-2' }}</th>
                            <th>{{ measure.allStats.mean | number:'1.0-2' }}</th>
                            <th *ngIf="measure.id === 'tree_yield_eff' && tonnesPerHaAvg">{{ measure.allStats.calculatedStats['tree_yield_eff'] }}</th>
                            <th *ngIf="measure.id === 'frut_acid' && sugarAcidRatioAvg">{{ measure.allStats.calculatedStats['frut_acid'] | number:'1.0-2' }}</th>
                            <th *ngIf="measure.id === 'frut_acid_citric' && sugarCitrusAcidRatioAvg">{{ measure.allStats.calculatedStats['frut_acid_citric'] | number:'1.0-2' }}</th>
                            <th *ngIf="measure.id === 'bnch_amal' && bunchSugarAcidRatioAvg">{{ measure.allStats.calculatedStats['bnch_amal'] | number:'1.0-2' }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </section>
    <!-- END REPORT SECTION: MEASURE SUMMARIES -->

    <!-- REPORT SECTION: CHAR INDEX SUMMARIES -->
    <section class="report-section report-section-indexes" *ngIf="charIndexSummary" [ngClass]="{'order-1': sequence.Indexes==1,
                                                                                                'order-2': sequence.Indexes==2,
                                                                                                'order-3': sequence.Indexes==3,
                                                                                                'order-4': sequence.Indexes==4,
                                                                                                'order-5': sequence.Indexes==5,
                                                                                                'order-6': sequence.Indexes==6,
                                                                                                'order-7': sequence.Indexes==7,
                                                                                                'order-8': sequence.Indexes==8,
                                                                                                'order-9': sequence.Indexes==9,
                                                                                                'order-10': sequence.Indexes==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">
                Characteristic Indexes
                <small>Characteric indexes combine specific characteristics to score a sample at every evaluation stage in the series.</small>
            </h3>
            <div class="cultivar-image" *ngIf="remarkImageOptions['charIndexSummary.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['charIndexSummary.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['charIndexSummary.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="charIndexSummary.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['charIndexSummary.intro']"
                    [documents]="remarkDocuments['charIndexSummary.intro']"
                    [images]="remarkImageOptions['charIndexSummary.intro']">
            </pv-report-remark>
            <p class="report-section-error" *ngFor="let error of charIndexSummary.errors">{{ error }}</p>
            <p class="report-section-empty" *ngIf="charIndexSummary.isEmpty">
                No indexes to report.
            </p>
        </div>

        <ng-container *ngFor="let indexReport of charIndexSummary.indexes; trackBy: trackById">
            <div class="report-subsection char-index-subsection" *ngIf="!indexReport.isEmpty || showEmptyFields">
                <h4 class="report-subsection-header">
                    {{ indexReport.index.label }}
                    <span class="report-header-right char-index-avg">
                        {{ indexReport.overallScore.avg | number:'1.1-1' }}
                    </span>
                    <small>{{ indexReport.index.description }}</small>
                </h4>
                <div class="cultivar-image" *ngIf="remarkImageOptions['charIndexSummary.indexes.' + indexReport.index.id]?.length">
                    <ng-container *ngFor="let image of remarkImageOptions['charIndexSummary.indexes.' + indexReport.index.id]">
                        <pv-image-ref
                            class="cultivar-image-thumb"
                            [fileRef]="image.fileRef"
                            [transformId]="imageTransformId('s')"
                            (click)="openRemarkImages(image, remarkImageOptions['charIndexSummary.indexes.' + indexReport.index.id])">
                        </pv-image-ref>
                    </ng-container>
                </div>
                <pv-report-remark class="report-section-remark"
                        [sectionId]="'charIndexSummary.indexes.' + indexReport.index.id"
                        [editable]="editable"
                        [text]="mappedRemarks['charIndexSummary.indexes.' + indexReport.index.id]"
                        [documents]="remarkDocuments['charIndexSummary.indexes.' + indexReport.index.id]"
                        [images]="remarkImageOptions['charIndexSummary.indexes.' + indexReport.index.id]">
                </pv-report-remark>

                <ng-container *ngIf="indexReport.charts as charts">
                    <pv-legacy-chart
                        *ngFor="let chart of charts"
                        class="char-index-chart"
                        [type]="chart.type"
                        [options]="chart.options"
                        [data]="chart.data"
                        [devicePixelRatio]="print ? printChartRatio : 1"
                        [chartLabel]="indexReport.index.label">
                    </pv-legacy-chart>
                </ng-container>

                <table class="report-table char-index-table" *ngIf="!indexReport.isEmpty && indexReport.showData">
                    <thead>
                        <tr>
                            <th>Series</th>
                            <th *ngFor="let char of indexReport.chars; let ci = index; trackBy: trackById">
                                {{ char.label }}
                            </th>
                            <th>Series AVG</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let seriesScore of indexReport.seriesScores; let i = index">
                            <tr *ngIf="seriesScore.isComplete">
                                <th><i
                                    [matTooltip]="(indexReport.series[i]) | descEval:indexReport.series[i].ref:indexReport.series[i].tooltipInfo?.cultivar:indexReport.series[i].tooltipInfo?.evalu"
                                    [matTooltipClass]="'mat-tooltip-custom'"
                                    class="si" [style.backgroundColor]="indexReport.series[i].color">{{ indexReport.series[i].ref }}</i>&nbsp;{{ indexReport.series[i].label }}</th>
                                <td *ngFor="let score of seriesScore.scores; let ci = index">{{ score | number:'1.1-1' }}  ({{ seriesScore.values[ci] | labelCharValue:indexReport.chars[ci].id }})</td>
                                <th>{{ seriesScore.avg | number:'1.1-1' }}</th>
                            </tr>
                        </ng-container>
                        <tr>
                            <th>Characteristic AVG</th>
                            <th *ngFor="let score of indexReport.overallScore.scores">{{ score | number:'1.1-1' }}</th>
                            <th>{{ indexReport.overallScore.avg | number:'1.1-1' }}</th>
                        </tr>
                    </tbody>
                </table>
                <p *ngIf="indexReport.isEmpty" class="char-index-empty">
                    Not enough data to generate index report.
                </p>
            </div>
        </ng-container>
    </section>
    <!-- END REPORT SECTION: CHAR INDEX SUMMARIES -->

    <!-- REPORT SECTION: IMAGES -->
    <section class="report-section report-section-images" *ngIf="imageOptions" [ngClass]="{'order-1': sequence.Images==1,
                                                                                            'order-2': sequence.Images==2,
                                                                                            'order-3': sequence.Images==3,
                                                                                            'order-4': sequence.Images==4,
                                                                                            'order-5': sequence.Images==5,
                                                                                            'order-6': sequence.Images==6,
                                                                                            'order-7': sequence.Images==7,
                                                                                            'order-8': sequence.Images==8,
                                                                                            'order-9': sequence.Images==9,
                                                                                            'order-10': sequence.Images==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">Evaluation Images</h3>
        </div>

        <div class="report-subsection image-list">

            <div class="cultivar-image" *ngIf="remarkImageOptions['eval-img.intro']?.length">
                <ng-container *ngFor="let image of remarkImageOptions['eval-img.intro']">
                    <pv-image-ref
                        class="cultivar-image-thumb"
                        [fileRef]="image.fileRef"
                        [transformId]="imageTransformId('s')"
                        (click)="openRemarkImages(image, remarkImageOptions['eval-img.intro'])">
                    </pv-image-ref>
                </ng-container>
            </div>
            <pv-report-remark class="report-section-remark"
                    sectionId="eval-img.intro"
                    [editable]="editable"
                    [text]="mappedRemarks['eval-img.intro']"
                    [documents]="remarkDocuments['eval-img.intro']"
                    [images]="remarkImageOptions['eval-img.intro']">
            </pv-report-remark>

            <div class="image-series-container" *ngFor="let serie of series; trackBy: trackByKey; index as si; let i = index;" >
                <div class="image-series-label" *ngIf="serie.images && serie.images.length > 0">
                    <div class="image-date" *ngIf="serie.siteLabel && imageOptions.showSite">{{ serie.siteLabel }}</div>
                    <div class="image-date" *ngIf="serie.samplePlantedDate && imageOptions.showPlantedYear">Planted Year: {{ serie.samplePlantedDate | amDateFormat:'YYYY' }}</div>
                    <i
                    [matTooltip]="(serie) | descEval:serie.ref:serie.tooltipInfo?.cultivar:serie.tooltipInfo?.evalu"
                    [matTooltipClass]="'mat-tooltip-custom'"
                    class="si" [style.backgroundColor]="serie.color">{{ serie.ref }}</i>&nbsp;{{ serie.label }}

                    <div class="cultivar-image" *ngIf="remarkImageOptions['image.markdown.' + serie.ref]?.length">
                        <ng-container *ngFor="let image of remarkImageOptions['image.markdown.' + serie.ref]">
                            <pv-image-ref
                                class="cultivar-image-thumb"
                                [fileRef]="image.fileRef"
                                [transformId]="imageTransformId('s')"
                                (click)="openRemarkImages(image, remarkImageOptions['image.markdown.' + serie.ref])">
                            </pv-image-ref>
                        </ng-container>
                    </div>
                    <pv-report-remark class="report-section-remark"
                        [sectionId]="'image.markdown.' + serie.ref"
                        [editable]="editable"
                        [text]="mappedRemarks['image.markdown.' + serie.ref]"
                        [documents]="remarkDocuments['image.markdown.' + serie.ref]"
                        [images]="remarkImageOptions['image.markdown.' + serie.ref]">
                    </pv-report-remark>
                    <br/>
                </div>
                <div class="image-section-group" cdkDropListGroup>
                    <div *ngFor="let image of imageSectionImages[si]; trackBy: trackByKey; index as i;"
                         cdkDropList
                         [cdkDropListData]="i"
                         cdkDropListOrientation="horizontal"
                         (cdkDropListDropped)="imageSectionDropped(si, i)"
                         [ngSwitch]="imageOptions.size"
                         class="image-section-droplist"
                         [class.small]="imageOptions.size == 's'"
                         [class.medium]="imageOptions.size == 'm'"
                         [class.large]="imageOptions.size == 'l'"
                         [class.extra]="imageOptions.size == 'xl'" >

                        <!-- IMAGE CONTAINER -->
                        <div class="image-section-box"
                            cdkDrag
                            [cdkDragData]="i"
                            (cdkDragMoved)="dragMoved($event)">

                            <!-- DRAG HANDLE -->
                            <div class="image-drag-handle" cdkDragHandle *ngIf="editable">
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>

                            <!-- IMAGES -->
                            <pv-image-ref *ngSwitchCase="'s'"  [fileRef]="image.fileRef" transformId="thumb_300" (click)="openImageGallery(image)"></pv-image-ref>
                            <pv-image-ref *ngSwitchCase="'m'"  [fileRef]="image.fileRef" transformId="thumb_400" (click)="openImageGallery(image)"></pv-image-ref>
                            <pv-image-ref *ngSwitchCase="'l'"  [fileRef]="image.fileRef" transformId="thumb_500" (click)="openImageGallery(image)"></pv-image-ref>
                            <pv-image-ref *ngSwitchCase="'xl'" [fileRef]="image.fileRef" transformId="thumb_600" (click)="openImageGallery(image)"></pv-image-ref>

                            <!-- IMAGE INFO -->
                            <div class="image-info overlay" *ngIf="imageOptions?.showDates || imageOptions?.showNotes">
                                <time class="image-date" *ngIf="image?.takenAt && imageOptions?.showDates">{{ image.takenAt | amDateFormat:'[W]W' }},&nbsp;{{ image.takenAt | amDateFormat:'ll' }}</time>
                                <div class="image-note" *ngIf="image?.note && imageOptions?.showNotes">{{ image.note }}</div>
                            </div>

                            <!-- DRAG PREVIEW -->
                            <pv-image-ref  *cdkDragPreview [fileRef]="image.fileRef" [transformId]="getTransformId(imageOptions.size)"></pv-image-ref>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>
    <!-- END REPORT SECTION: IMAGES -->

    <!-- REPORT SECTION: NOTES -->
    <section class="report-section report-section-notes" *ngIf="noteOptions" [ngClass]="{'order-1': sequence.Notes==1,
                                                                                        'order-2': sequence.Notes==2,
                                                                                        'order-3': sequence.Notes==3,
                                                                                        'order-4': sequence.Notes==4,
                                                                                        'order-5': sequence.Notes==5,
                                                                                        'order-6': sequence.Notes==6,
                                                                                        'order-7': sequence.Notes==7,
                                                                                        'order-8': sequence.Notes==8,
                                                                                        'order-9': sequence.Notes==9,
                                                                                        'order-10': sequence.Notes==10}">

        <div class="report-section-intro">
            <h3 class="report-section-header">Evaluation Notes</h3>
        </div>
        <div class="report-subsection">
            <ng-container *ngFor="let serie of series; trackBy: trackByKey; let i = index">
                <ng-container *ngIf="serie.notes.length">
                    <h4 class="note-header">
                        <i
                        [matTooltip]="(serie) | descEval:serie.ref:serie.tooltipInfo?.cultivar:serie.tooltipInfo?.evalu"
                        [matTooltipClass]="'mat-tooltip-custom'"
                        class="si" [style.backgroundColor]="serie.color">{{ serie.ref }}</i>
                        &nbsp;{{ serie.label }}
                    </h4>
                    <div class="note-item" *ngFor="let note of serie.notes; trackBy: trackByKey">
                        <time class="note-date" *ngIf="note.takenAt" [attr.datetime]="note.takenAt">{{ note.takenAt | amDateFormat:'[W]W' }},&nbsp;{{ note.takenAt | amDateFormat:'ll' }}</time>
                        <p class="note-message">{{ note.message }}</p>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>
    <!-- END REPORT SECTION: NOTES -->
</div>
<!--SECTIONS END-->

<footer class="report-section report-footer">

    <div class="cultivar-image" *ngIf="remarkImageOptions['conclusion']?.length">
        <ng-container *ngFor="let image of remarkImageOptions['conclusion']">
            <pv-image-ref
                class="cultivar-image-thumb"
                [fileRef]="image.fileRef"
                [transformId]="imageTransformId('s')"
                (click)="openRemarkImages(image, remarkImageOptions['conclusion'])">
            </pv-image-ref>
        </ng-container>
    </div>
    <pv-report-remark class="report-section-remark"
        sectionId="conclusion"
        [editable]="editable"
        [text]="mappedRemarks['conclusion']"
        [documents]="remarkDocuments['conclusion']"
        [images]="remarkImageOptions['conclusion']">
    </pv-report-remark>

    <div class="cultivar-image" *ngIf="remarkImageOptions['disclaimer']?.length">
        <ng-container *ngFor="let image of remarkImageOptions['disclaimer']">
            <pv-image-ref
                class="cultivar-image-thumb"
                [fileRef]="image.fileRef"
                [transformId]="imageTransformId('s')"
                (click)="openRemarkImages(image, remarkImageOptions['disclaimer'])">
            </pv-image-ref>
        </ng-container>
    </div>
    <pv-report-remark class="report-section-disclaimer report-section-remark"
        sectionId="disclaimer"
        [editable]="editable"
        [text]="mappedRemarks['disclaimer']"
        [documents]="remarkDocuments['disclaimer']"
        [images]="remarkImageOptions['disclaimer']">
    </pv-report-remark>

    <div class="logo-footer">
        <p class="brand-line">Report powered by Culteva&trade;</p>
    </div>

</footer>
