import { Chart, ChartConfiguration, ChartOptions, ChartTypeRegistry, RadialLinearScale, registerables } from 'chart.js';
import "@sgratzl/chartjs-chart-boxplot/build/index.umd.js";
import 'chartjs-adapter-moment';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Dialog } from '@core/material';
import { ChartOptionsDialog, ChartOptionsDialogData } from '../chart-options-dialog/chart-options.dialog';
import { ComparativeSummaryOptions, MeasurementSummaryOverrides, ReportSample } from '@core/data';
import { CompiledComparativeMeasurementSummary } from '@app/evaluation/report-compiler.types';

Chart.register(...registerables);

@Component({
    selector: 'pv-chart',
    templateUrl: 'pv-chart.component.html',
    styleUrls: ['pv-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-chart'
    }
})
export class ChartComponent implements OnDestroy, AfterViewInit, OnChanges {

    @Input()
    @HostBinding('class.print')
    print = false;

    //? INPUTS
    @Input()
    set summary(summary: CompiledComparativeMeasurementSummary) {
        if (summary && this._summary$.getValue() !== summary) {
            this._summary$.next(summary);
        }
    }

    @Input()
    set type(type: string) {
        if (type && this._type$.getValue() !== type) {
            this._type$.next(type);
        }
    }

    @Input()
    set devicePixelRatio(ratio: number) {
        if (ratio && this._devicePixelRatio$.getValue() !== ratio) {
            this._devicePixelRatio$.next(ratio);
        }
    }

    @Input()
    measureId: string;

    @Input()
    editable: boolean = true;

    @Input()
    chartReady: boolean = false;

    @Input()
    chartLabel: string;

    @Input()
    samples: ReportSample[]

    @Input()
    overrides: MeasurementSummaryOverrides;

    //? OUTPUTS
    @Output()
    onEditChart = new EventEmitter<ComparativeSummaryOptions>();

    //? VIEW CHILDREN
    @ViewChild('pvCanvas', { static: false })
    canvasRef: ElementRef;

    @ViewChild('pvChartContainer', { static: false })
    containerRef: ElementRef;

    diaplayLabel: string;
    firstLoad: boolean = true;

    //? Plugin for short grid lines, source: https://youtu.be/EV9VUQQPNxw?si=wSoyUbCCuRE80tfw
    shortGridLines = {
        id: 'shortGridLines',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx, chartArea: {bottom, top}, scales: {x} } = chart;

            ctx.save();

            for (let i = 0; i < x._gridLineItems.length; i++) {
                ctx.beginPath();
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#9c9c9c';
                ctx.moveTo(x._gridLineItems[i].tx1, bottom);
                ctx.lineTo(x._gridLineItems[i].tx1, bottom - (top / 10));
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
            }
        }
    }

    meta: any;

    private _summary$ = new BehaviorSubject<CompiledComparativeMeasurementSummary>(null);
    private _type$ = new BehaviorSubject<string>(null);
    private _devicePixelRatio$ = new BehaviorSubject<number>(null);
    private _destroy$ = new Subject();

    private _canvas: HTMLCanvasElement;
    private _chart: Chart;

    constructor(
        private _dialog: Dialog,
        private _changeRef: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.chartReady = this._summary$.getValue().chart ? true : false;
        this.diaplayLabel = this.overrides?.options?.altLabel ? this.overrides.options.altLabel : this.chartLabel;

        if (!this.firstLoad) this.updateChart();

        this.meta = this.getChartMeta();
    }

    ngAfterViewInit() {
        this.updateChart()
        this.firstLoad = false;
    }

    editChartOptions() {
        if (!this.editable) return;

        const data: ChartOptionsDialogData = {
            measureId: this.measureId,
            samples: this.samples,
            overrides: this.overrides,
            series: this._summary$.value.series,
        }

        this._dialog.openFullscreen(ChartOptionsDialog, {data}).afterClosed()
            .subscribe(result => {
                if (!result) return
                this.onEditChart.emit(result);
            })
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();

        if (this._chart) {
            this._chart.destroy();
            this._chart = null;
        }

        this._summary$.complete();
    }

    getChartType() {
        return this._type$.value;
    }

    getChartMeta() {
        return this._summary$.value?.chart?.misc || null;
    }

    private updateChart() {
        if (!this.chartReady) return;

        this._canvas = this.canvasRef.nativeElement;

        combineLatest(
                this._summary$,
                this._type$,
                this._devicePixelRatio$
            )
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(100),
            )
            .subscribe(changes => {

                let [summary, type, devicePixelRatio]: any = changes;
                let chart = summary.chart

                try {

                    if (!summary) {
                        if (this._chart) {
                            this._chart.destroy();
                            this._chart = null;
                        }
                    }

                    const config = this._chart ? this._chart.config as ChartConfiguration : null;


                    if (!this._chart || config.type !== type) {
                        if (this._chart) this._chart.destroy();

                        this._chart = new Chart(this._canvas, {
                            type: type,
                            options: { ...chart.options, devicePixelRatio },
                            data: chart.data,
                            plugins: [
                                this.shortGridLines,
                            ]
                        });
                    } else {
                        this._chart.options = { ...chart.options, devicePixelRatio };
                        this._chart.data = chart.data;
                        this._chart.update();
                    }

                } catch (error) {
                    console.error("ChartComponent: Error compiling chart", error);

                    if (this._chart) {
                        this._chart.destroy();
                        this._chart = null;
                    }
                }

            });
    }
}
