import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Dialog } from "@core/material";
import { ChartOptions } from 'chart.js';
import { Subject } from "rxjs";

export interface ChartFullscreenDialogData {
    type: string;
    data: any;
    options: ChartOptions,
    ratio: number,
    title: string,
}

@Component({
    selector: 'pv-chart-fullscreen-dialog',
    templateUrl: 'chart-fullscreen.dialog.html',
    styleUrls: ['chart-fullscreen.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-fullscreen-dialog'
    }
})
export class ChartFullscreenDialog implements  OnDestroy {

    private _destroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChartFullscreenDialogData
    ) { }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}